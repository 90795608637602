const getDefaultState = () => {
  return {
    /* list: null,
        count: 0,
        originFilter: {}, */
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    /* totalPages: state => {
            const limit = state.originFilter?.limit || 20
            const count = state.count || 0
            return Math.ceil(count / limit)
        }, */
  },
  mutations: {
    /* resetState(state) {
            Object.assign(state, getDefaultState())
        },
        setResponse(state, data) {
            state.list = data.memberships
            state.count = data.totalCount
        },
        setOriginFilter(state, filter) {
            state.originFilter = JSON.parse(JSON.stringify(filter))
        }, */
  },
  actions: {
    // 인증번호 받기
    async postUserMobile2fa (context, payload) {
      let response = null

      try {
        response = await this._vm.post(
                `${this._vm.SPARKONE_OFFICE_API_URL}office/user/mobile/2fa`
                , payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    // 인증번호 검증하기
    async postUserMobile2faCheck (context, payload) {
      let response = null

      try {
        response = await this._vm.post(
                  `${this._vm.SPARKONE_OFFICE_API_URL}office/user/mobile/2fa/${payload.key}`
                  , payload.data,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    // 아이디 중복확인
    async getUserIdCheck (context, payload) {
      let response = null

      try {
        response = await this._vm.get(
                  `${this._vm.SPARKONE_OFFICE_API_URL}office/user/login-id/${payload.id}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 회원가입(토큰 없는경우)
    async postUserSignup (context, payload) {
      let response = null

      try {
        response = await this._vm.post(
                    `${this._vm.SPARKONE_OFFICE_API_URL}office/user`,
                    payload.data,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    // 회원가입(토큰 있는경우)
    async postUserSignupToken (context, payload) {
      let response = null

      try {
        response = await this._vm.post(
                    `${this._vm.SPARKONE_OFFICE_API_URL}office/user/token/${payload.token}`,
                    payload.data,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // #####################
    async getUserList (context, payload) {
      let response = null

      try {
        response = await this._vm.get(
                    `${this._vm.SPARKONE_OFFICE_API_URL}user`
                    , payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }

      return response
    },
    async getUserInfo (context, payload) {
      let response = null

      try {
        response = await this._vm.get(
                    `${this._vm.SPARKONE_OFFICE_API_URL}user/${payload.userId}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }

      return response
    },

    // 멤버-서비스 이용약관 및 개인정보 수집·이용 동의
    async getServiceTerms (context, payload) {
      let response = null

      try {
        response = await this._vm.get(
                    `${this._vm.SPARKONE_MEMBER_API_URL}service/terms`,
                    payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

  },
}
