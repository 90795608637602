export const BRAND_CODE = {
    SPARKPLUS: 'SPARKPLUS',
    CONCREATE: '콘크리에이트',
    ARTISANS_FIVE: '아티장스파이브',
    SUGARMAN_WORK: '슈가맨워크',
    VEEWORK: '비워크',
    PHILO_SPACE: '필로스페이스',
    WORKBOX: '워크박스',
    DEARGEN: '디어젠',
    KISED: '글로벌창업사관학교',
    INTERVEST: '인터베스트',
    DINNO_LAB: '디노랩',
    BORATR: '보라티알',
    CREATIVE_KOREA: '서울창조경제혁신센터',
    THE_CONNOISSEUR: '더코노셔',
}

export const SPACE_CODE = {
    'lounge-type': '라운지',
    'office-type': '오피스',
}

export const SPACE_SUB_CODE = {
    'lounge': '라운지',
    'office-room': '오피스(호)',
    'office-seat': '오피스(좌석)',
    'office-address': '오피스(주소지)',
}

export const CONTRACT_CODE = {
    'counsel-contract': '상담계약(계약형)',
    'order-non-contract': '주문계약(비계약형)',
}

export const PROMOTION_LIMIT_CODE = {
    'no-limit': '제한없음',
    'limit-dup-goodsid': '동일 상품 중복 불가',
    'limit-dup-eventid': '동일 프로모션 중복 불가',
    'limit-reward-count': '전체 중복 불가'
}

export const CHARGE_TYPE = {
    member: '계약인원',
    none: '차감안함'
}

export const MEETING_ROOM_PAYMENT_TYPE = {
  credit: '크레딧',
  cash: '현금',
  none: '차감안함'
}

export const WEEKEND = {
    monday: '월',
    tuesday: '화',
    wednesday: '수',
    thursday: '목',
    friday: '금',
    saturday: '토',
    sunday: '일',
}

export const RESERVATION_STATUS = {
    'normal': '정상',
    'canceled': '취소',
    'reservation': '예약',
    'reservation-cancel': '예약취소',
    'in-use': '이용중',
    'canceled-in-use': '이용중취소',
    'complete': '이용완료',
}

// 솔루션 상품 페이지 카테고리 부모키
export const SOLUTION_PRODUCT_PARENT_CATEGORY_ID = process.env.VUE_APP_MODE !== 'production' ? 46 : 0

export const bankList = [
    { id: '001', name: '한국은행' },
    { id: '002', name: '산업은행' },
    { id: '003', name: '기업은행' },
    { id: '004', name: '국민은행' },
    { id: '007', name: '수협은행' },
    { id: '008', name: '수출입은행' },
    { id: '011', name: '농협은행' },
    { id: '012', name: '지역농․축협' },
    { id: '020', name: '우리은행' },
    { id: '023', name: 'SC제일은행' },
    { id: '027', name: '한국씨티은행' },
    { id: '031', name: '대구은행' },
    { id: '032', name: '부산은행' },
    { id: '034', name: '광주은행' },
    { id: '035', name: '제주은행' },
    { id: '037', name: '전북은행' },
    { id: '039', name: '경남은행' },
    { id: '041', name: '우리카드' },
    { id: '044', name: '외환카드' },
    { id: '045', name: '새마을금고중앙회' },
    { id: '048', name: '신협' },
    { id: '050', name: '저축은행' },
    { id: '052', name: '모건스탠리은행' },
    { id: '054', name: 'HSBC은행' },
    { id: '055', name: '도이치은행' },
    { id: '057', name: '제이피모간체이스은행' },
    { id: '058', name: '미즈호은행' },
    { id: '059', name: '엠유에프지은행' },
    { id: '060', name: 'BOA은행' },
    { id: '061', name: '비엔피파리바은행' },
    { id: '062', name: '중국공상은행' },
    { id: '063', name: '중국은행' },
    { id: '064', name: '산림조합중앙회' },
    { id: '065', name: '대화은행' },
    { id: '066', name: '교통은행' },
    { id: '067', name: '중국건설은행' },
    { id: '071', name: '우체국' },
    { id: '076', name: '신용보증기금' },
    { id: '077', name: '기술보증기금' },
    { id: '081', name: '하나은행' },
    { id: '088', name: '신한은행' },
    { id: '089', name: '케이뱅크' },
    { id: '090', name: '카카오뱅크' },
    { id: '101', name: '한국신용정보원' },
    { id: '102', name: '대신저축은행' },
    { id: '103', name: '에스비아이저축은행' },
    { id: '104', name: '에이치케이저축은행' },
    { id: '105', name: '웰컴저축은행' },
    { id: '106', name: '신한저축은행' },
    { id: '209', name: '유안타증권' },
    { id: '218', name: 'KB증권' },
    { id: '221', name: '상상인증권' },
    { id: '222', name: '한양증권' },
    { id: '223', name: '리딩투자증권' },
    { id: '224', name: 'BNK투자증권' },
    { id: '225', name: 'IBK투자증권' },
    { id: '227', name: 'KTB투자증권' },
    { id: '238', name: '미래에셋대우' },
    { id: '240', name: '삼성증권' },
    { id: '243', name: '한국투자증권' },
    { id: '247', name: 'NH투자증권' },
    { id: '261', name: '교보증권' },
    { id: '262', name: '하이투자증권' },
    { id: '263', name: '현대차증권' },
    { id: '264', name: '키움증권' },
    { id: '265', name: '이베스트투자증권' },
    { id: '266', name: 'SK증권' },
    { id: '267', name: '대신증권' },
    { id: '269', name: '한화투자증권' },
    { id: '270', name: '하나금융투자' },
    { id: '278', name: '신한금융투자' },
    { id: '279', name: 'DB금융투자' },
    { id: '280', name: '유진투자증권' },
    { id: '287', name: '메리츠종합금융증권' },
    { id: '288', name: '카카오페이증권' },
    { id: '290', name: '부국증권' },
    { id: '291', name: '신영증권' },
    { id: '292', name: '케이프투자증권' },
    { id: '293', name: '한국증권금융' },
    { id: '294', name: '한국포스증권' },
    { id: '295', name: '우리종합금융' },
]
