<template>
  <nav v-if="totalPages">
    <ul v-if="originFilter">
      <li>
        <button class="button" @click.prevent="paginateTo(1)">
          <!-- <ion-icon name="caret-back-outline"></ion-icon> -->
          <svg
            :class="{ on: nextPage > 1 }"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M16 5L11 10L16 15" stroke="#BCBCBC" stroke-width="1.7" />
            <path d="M9 5L4 10L9 15" stroke="#BCBCBC" stroke-width="1.7" />
          </svg>
        </button>
      </li>
      <li>
        <button
          class="button"
          @click.prevent="paginateTo(currentPage - 1 > 1 ? currentPage - 1 : 1)"
        >
          <!-- <ion-icon name="chevron-back-outline"></ion-icon> -->
          <svg
            :class="{ on: nextPage > 1 }"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.5 5L6.5 10L11.5 15"
              stroke="#BCBCBC"
              stroke-width="1.7"
            />
          </svg>
        </button>
      </li>
      <li>
        <sp-input
          @keyup.enter="paginateTo(nextPage)"
          :style="{ width: inputWidth }"
          type="number"
          min="1"
          v-model="nextPage"
        />
        <span> / </span>
        <span class="total" ref="total">
          {{ totalPages }}
        </span>
      </li>
      <li>
        <button
          class="button"
          @click.prevent="
            paginateTo(
              currentPage + 1 < totalPages ? currentPage + 1 : totalPages
            )
          "
        >
          <!-- <ion-icon name="chevron-forward-outline"></ion-icon> -->
          <svg
            :class="{ on: nextPage < totalPages }"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.5 5L13.5 10L8.5 15"
              stroke="#BCBCBC"
              stroke-width="1.7"
            />
          </svg>
        </button>
      </li>
      <li>
        <button class="button" @click.prevent="paginateTo(totalPages)">
          <!-- <ion-icon name="caret-forward-outline"></ion-icon> -->
          <svg
            :class="{ on: nextPage < totalPages }"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M4 5L9 10L4 15" stroke="#BCBCBC" stroke-width="1.7" />
            <path d="M11 5L16 10L11 15" stroke="#BCBCBC" stroke-width="1.7" />
          </svg>
        </button>
      </li>
    </ul>
    <ul v-else>
      <li>
        <router-link class="button" tag="button" :to="paginateObject(1)">
          <!-- <ion-icon name="caret-back-outline"></ion-icon> -->
          <svg
            :class="{ on: nextPage > 1 }"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M16 5L11 10L16 15" stroke="#BCBCBC" stroke-width="1.7" />
            <path d="M9 5L4 10L9 15" stroke="#BCBCBC" stroke-width="1.7" />
          </svg>
        </router-link>
      </li>
      <li>
        <router-link
          class="button"
          tag="button"
          :to="paginateObject(currentPage - 1 > 1 ? currentPage - 1 : 1)"
        >
          <!-- <ion-icon name="chevron-back-outline"></ion-icon> -->
          <svg
            :class="{ on: nextPage > 1 }"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.5 5L6.5 10L11.5 15"
              stroke="#BCBCBC"
              stroke-width="1.7"
            />
          </svg>
        </router-link>
      </li>
      <li>
        <sp-input
          @keyup.enter="$router.push(paginateObject(nextPage))"
          :style="{ width: inputWidth }"
          type="number"
          min="1"
          v-model="nextPage"
        />
        <span>/</span>
        <span class="total" ref="total">
          {{ totalPages }}
        </span>
      </li>
      <li>
        <router-link
          class="button"
          tag="button"
          :to="
            paginateObject(
              currentPage + 1 < totalPages ? currentPage + 1 : totalPages
            )
          "
        >
          <!-- <ion-icon name="chevron-forward-outline"></ion-icon> -->
          <svg
            :class="{ on: nextPage < totalPages }"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.5 5L13.5 10L8.5 15"
              stroke="#BCBCBC"
              stroke-width="1.7"
            />
          </svg>
        </router-link>
      </li>
      <li>
        <router-link
          class="button"
          tag="button"
          :to="paginateObject(totalPages)"
        >
          <!-- <ion-icon name="caret-forward-outline"></ion-icon> -->
          <svg
            :class="{ on: nextPage < totalPages }"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M4 5L9 10L4 15" stroke="#BCBCBC" stroke-width="1.7" />
            <path d="M11 5L16 10L11 15" stroke="#BCBCBC" stroke-width="1.7" />
          </svg>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'Pagination',
  data () {
    return {
      currentPage: null,
      nextPage: null,
      inputWidth: '20px',
    }
  },
  props: {
    totalPages: {
      type: Number,
      required: true,
    },
    pageParameter: {
      type: String,
      default: 'page',
    },
    originFilter: {
      type: Object,
      default: null,
    },
  },
  methods: {
    paginateObject (pageTo) {
      return {
        query: {
          ...this.$route.query,
          [this.pageParameter]: pageTo,
        },
      }
    },
    async paginateTo (pageTo) {
      this.currentPage = pageTo
      const payload = {
        ...this.originFilter,
        [this.pageParameter]: pageTo,
      }
      this.$emit('loadData', payload)
    },
  },
  mounted () {
    if (this.originFilter) {
      this.currentPage
        = parseInt(this.originFilter[this.pageParameter], 10) || 1
    } else {
      this.currentPage
        = parseInt(this.$route.query[this.pageParameter], 10) || 1
    }
    this.$store.commit('setCurrentPage', this.currentPage)
  },
  watch: {
    $route () {
      if (this.originFilter) {
        this.currentPage
          = parseInt(this.originFilter[this.pageParameter], 10) || 1
      } else {
        this.currentPage
          = parseInt(this.$route.query[this.pageParameter], 10) || 1
      }
      this.$store.commit('setCurrentPage', this.currentPage)
    },
    currentPage (to) {
      this.nextPage = to
      this.$nextTick(() => {
        this.inputWidth
          = (this.$refs.total?.getBoundingClientRect()?.width || 35) + 25 + 'px'
      })
    },
    nextPage () {
      if (this.nextPage > this.totalPages) this.nextPage = this.totalPages
      if (this.nextPage < 1) this.nextPage = 1
    },
    totalPages () {
      this.$nextTick(() => {
        this.inputWidth
          = (this.$refs.total?.getBoundingClientRect()?.width || 35) + 25 + 'px'
      })
    },
  },
}
</script>

<style lang="scss" scoped>
svg.on path {
  stroke: var(--gray-100);
}

ion-icon {
  vertical-align: top;
}

nav {
  width: fit-content;
  margin: 48px auto 0 auto;
  font-size: 14px;

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 30px;

    /deep/ li {
      display: flex;
      align-items: center;
      margin: 0;

      &:nth-child(1) {
        margin-right: 20px;
      }

      &:nth-child(3) {
        margin: 0 27px;
        gap: 8px;

        input,
        .total {
          color: #000;
          font-size: 15px;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.2px;
        }

        input {
          line-height: 30px;
          height: 30px;
          text-align: center;
          padding: 0;
          margin: 0;
          text-indent: 0;
          color: var(--gray-100);
        }
      }

      &:nth-child(5) {
        margin-left: 20px;
      }

      .button {
        // min-width: 43px;
        // color: #777;
        // // color: black;
        // cursor: pointer;
        // margin: 1px;
        // height:26px;
        // border-radius: 5px;
        // border:1px solid #ddd;
        // background-color: #fff;
        // line-height:14px;
        cursor: pointer;
        border: none;
        background: none;
        width: 20px;
        height: 20px;
        padding: 0;
        margin: 0;
      }
    }
  }
}

.no-list {
  color: #000;
  font-size: 15px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.2px;
}

// 페이지네이션 내부의 input-type = number 에 자동으로 생기는 상하 버튼 제거
/deep/ .wrap-input {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox  */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
</style>
