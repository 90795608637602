<template>
  <nav class="navigation">

    <div class="welcome-message">
      <div class="ellipsis">
        <span>{{ userName }}</span>님
      </div>
      <span> 안녕하세요! </span>
    </div>

    <div class="custom-select" :class="{ 'show': toggle01 }" v-show="isShowTenantSelectBox" @mouseleave="toggle01 = false">
      <div class="selected" :class="{ 'active': toggle01,'icon':availableTenantList.length > 1 }" @click="toggle01 = !toggle01; toggle02 = false">
        <strong>
          기업
        </strong>
        <span class="item">
          {{ selectetTenantName }}
        </span>
      </div>

      <ul class="select-list" :style="{'overflow-y': availableTenantList.length <= 5 ? 'hidden': 'auto'}">
        <li class="item"
          v-for="(item, index) in availableTenantList"
          @click="onSelected01(item)"
          :key="`tl${index}`">{{ item.tenantName }}</li>
      </ul>
    </div>

    <div class="custom-select" :class="{ 'show': toggle02 }" @mouseleave="toggle02 = false">
      <div class="selected" :class="{ 'active': toggle02,'icon':selectableAccountList.length > 1  }" @click="toggle02 = !toggle02; toggle01 = false">
        <strong>
          어카운트
        </strong>
        <span class="item">
          {{ selectedAccountMembershipName }}
        </span>
      </div>

      <ul class="select-list" :style="{'overflow-y': selectableAccountList.length <= 5 ? 'hidden': 'auto'}">
        <li class="item"
          v-for="(item, index) in selectableAccountList"
          @click="onSelected02(item)"
          :key="`ac${index}`">{{ item.membershipName }}</li>
      </ul>
    </div>

    <!-- <div class="logo" @click="moveMainPage">
      <img
        v-if="isTenantLogo == true"
        :src="tenantLogoImageUrl"
        loading="lazy"
      />
      <img
        v-if="isTenantLogo == false"
        src="@/assets/logo_office_b.png"
        alt="office_b"
        loading="lazy"
      />
    </div> -->
    <!-- //logo -->

    <div class="user-info" @click="onMenuShow" v-if="false">
      <svg
        class="user-info-icon"
        :class="{ on: isHiddenMenu }"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M18 10L12 16L6 10" stroke="#E9E9E9" stroke-width="2" />
      </svg>

      <div class="user-info-title">
        <div class="ellipsis">
          <span>{{ userName }}</span
          >님
        </div>
        <span> 안녕하세요! </span>
      </div>

      <div class="user-info-sub">
        <span>{{ selectedAccountMembershipName }}</span>
        <i>/</i>
        <span>{{ selectetTenantName }}</span>
        <!-- <span>{{ selectedManagerTypeName }}</span> -->
      </div>

      <div class="hidden-menu" @click.stop v-if="isHiddenMenu">
        <div>
          <strong class="label">어카운트</strong>
          <sp-select
            v-model="selectedAccountId"
            @change="onChangeAccountId"
            icon="white"
            style="height: 32px"
          >
            <option
              v-for="(item, index) in selectableAccountList"
              :value="item.membershipId"
              :key="`ac${index}`"
            >
              {{ item.membershipName }}
            </option>
          </sp-select>
        </div>
        <div>
          <strong class="label">기업</strong>
          <!-- <sp-select :disabled="!isSetMembershipId" icon="white" v-model="selectedManagerType" style="height:32px;">
            <option v-for="(item, index) in availableManagerTypeList" :value="item.managerType" :key="`mt${index}`">
              {{ item.managerTypeName }}
            </option>
          </sp-select> -->

          <sp-select
            v-show="isShowTenantSelectBox"
            icon="white"
            v-model="selectedTenantId"
            style="height: 32px"
          >
            <option
              v-for="(item, index) in availableTenantList"
              :value="item.tenantId"
              :key="`tl${index}`"
            >
              {{ item.tenantName }}
            </option>
          </sp-select>
        </div>

        <div class="btn-box">
          <router-link class="btn-logout" tag="sp-button" to="/logout"
            >로그아웃
          </router-link>
        </div>
      </div>
    </div>
    <!-- //user-info -->

    <ul class="main-navigation">
      <template v-for="(menu, i) in tree">
        <li
          :class="{ toggle: i === selectedMenuIndex }"
          :key="i"
          v-if="tree != {}"
        >
          <a
            @click="isToggleShow(i)"
            :class="{ home: i === 0 }"
            :style="{
              pointerEvent: !menu.menuUrl ? 'none' : 'auto',
              cursor: 'pointer',
            }"
          >
            <svg
              class="icon-arrow"
              width="6"
              height="10"
              viewBox="0 0 6 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                id="Vector 229"
                d="M0.5 1L4.5 5L0.5 9"
                stroke="#E9E9E9"
                stroke-width="1.4"
              />
            </svg>
            {{ menu.menuNm }}
          </a>
          <ul v-if="menu.child" class="sub-navigation">
            <li v-for="(childMenu, j) in menu.child" :key="j">
              <router-link
                :to="childMenu.menuUrl"
                :class="{
                  active: new RegExp(childMenu.menuUrlMatchRegex).test(
                    $route.path
                  ),
                }"
              >
                {{ childMenu.menuNm }}
              </router-link>
            </li>
          </ul>
        </li>
      </template>

      <!-- <li><a href="https://drive.google.com/file/d/1m6YB96wZwCKyWHVV_7xbPu9_GHDRXtel/view?usp=sharing" target="_blank" style="font-weight:bold;color:var(--theme-secondary-color)">어드민 이용 가이드</a></li> -->
      <!-- 임시퍼블주소 -->
      <!-- 임시퍼블주소 -->
      <!-- 임시퍼블주소 -->
      <!-- 임시퍼블주소 -->
      <!-- 임시퍼블주소 -->
      <!-- <li>
          <h3>계약관리</h3>
          <ul class="sub-navigation" style="display:block">
            <li>
              <router-link to="/contract/status-inquiry">
                계약 현황 조회
              </router-link>
            </li>
            <li>
              <router-link to="/contract/monthly-billing">
                월 청구서
              </router-link>
            </li>
          </ul>
          <br>
      </li>
      <li>
        <h3>멤버 관리</h3>
        <ul class="sub-navigation" style="display:block">
          <li>
            <router-link to="/member/member-infoList">
              멤버 정보
            </router-link>
          </li>
          <li>
            <router-link to="/member/member-registration">
              멤버 등록
            </router-link>
          </li>
          <li>
            <router-link to="/member/member-departments">
              팀 설정
            </router-link>
          </li>
        </ul>
        <br>
      </li>
      <li>
        <h3>관리자 설정</h3>
        <ul class="sub-navigation" style="display:block">
          <li>
            <router-link to="/manager/manager-infoList">
              관리자 정보
            </router-link>
          </li>
          <li>
            <router-link to="/manager/manager-registration">
              관리자 등록
            </router-link>
          </li>
        </ul>
        <br>
      </li> -->
      <!-- //임시퍼블주소 -->
      <!-- //임시퍼블주소 -->
      <!-- //임시퍼블주소 -->
      <!-- //임시퍼블주소 -->
      <!-- //임시퍼블주소 -->
    </ul>
    <!-- //main-navigation -->

    <!-- <div class="btn-guide-box">
      <button @click="clickFileDownload('/pdf/마이오피스B_이용_가이드.pdf','[마이오피스B]_이용_가이드')">
        마이오피스B 이용가이드
        <svg
              class="icon-arrow"
              width="6"
              height="10"
              viewBox="0 0 6 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                id="Vector 229"
                d="M0.5 1L4.5 5L0.5 9"
                stroke="#E9E9E9"
                stroke-width="1.4"
              />
            </svg>
      </button>
    </div> -->

  </nav>
  <!-- //navigation -->
</template>
<script>
export default {
  name: 'Navigation',
  data () {
    return {
      isRefreshed: false,
      isAccountIdChanged: false,
      isTenantLogo: null,
      tenantLogoImageUrl: '',
      isHiddenMenu: false,

      toggle01: false,
      toggle02: false,
    }
  },
  async mounted () {
    this.isAccountIdChanged = false
    if (this.selectedAccountId === 0) {
      this.isRefreshed = true
      this.selectedAccountId
        = window.localStorage.getItem('selected_account_id') || 0
    }
    if (this.selectedManagerType === null) {
      this.selectedManagerType
        = window.localStorage.getItem('selected_manager_type') || ''
    }
    if (this.selectedTenantId === 0) {
      this.selectedTenantId
        = window.localStorage.getItem('selected_tenant_id') || 0
    }
    // 입주사 이미지 처리
    this.setTenantLogoImage()
  },
  computed: {
    selectedAccountMembershipName () {
      return this.selectableAccountList
        ? this.selectableAccountList.filter(
          (i) => Number(i.membershipId) === Number(this.selectedAccountId),
        )[0]?.membershipName
        : '-'
    },
    selectedManagerTypeName () {
      return this.availableManagerTypeList
        ? this.availableManagerTypeList.filter(
          (i) => i.managerType === this.selectedManagerType,
        )[0]?.managerTypeName
        : '-'
    },
    selectetTenantName () {
      return this.availableTenantList
        ? this.availableTenantList.filter(
          (i) => i.tenantId === Number(this.selectedTenantId),
        )[0]?.tenantName
        : '-'
    },

    selectedAccountId: {
      get () {
        return this.$store.state.user.selectedAccountId
      },
      async set (newValue) {
        const oldSelectedManagerType
          = this.$store.state.user.selectedManagerType

        // 새로운 값 설정
        this.$store.commit('user/setSelectedAccountId', newValue)
        window.localStorage.setItem('selected_account_id', newValue)

        // 새로고침 했을 때 local storage 에서 타입 가져옴
        if (this.isRefreshed) {
          this.selectedManagerType = window.localStorage.getItem(
            'selected_manager_type',
          )
        } else {
          this.isAccountIdChanged = true

          // 관리자 유형 중 입주사 관리자를 우선적으로 자동 선택
          const managerType = this.selectableAccountListByKey[
            this.selectedAccountId
          ]?.managerTypeList?.find(
            (item) => item.managerType === 'tenant-manager',
          )?.managerType
          this.selectedManagerType = managerType || 'membership-manager'
        }

        // this.log(window.localStorage.getItem('selected_tenant_id'), '지금이니?')
        const tenantId
          = this.selectableAccountListByKey[this.selectedAccountId][
            'tenant-manager'
          ][0]?.tenantId
        this.$store.commit('user/setSelectedTenantId', tenantId)
        window.localStorage.setItem('selected_tenant_id', tenantId)
        // this.log(window.localStorage.getItem('selected_tenant_id'), '지금이니?set')

        // this.log(this.selectableAccountListByKey[this.selectedAccountId], 'this.selectableAccountListByKey[this.selectedAccountId]')

        // 관리자 타입이 안바뀌면 여기서 메뉴 조회
        if (this.selectedManagerType === oldSelectedManagerType) {
          const managerId
            = this.selectableAccountListByKey[this.selectedAccountId]?.[
              this.selectedManagerType
            ]?.[0].managerId

          // 새로고침 했을 때
          if (this.isRefreshed) {
            // console.log('here7')
            await this.$store.dispatch('user/getMenu', {
              membershipId: window.localStorage.getItem('selected_account_id'),
              managerType: window.localStorage.getItem('selected_manager_type'),
              tenantId: window.localStorage.getItem('selected_tenant_id') ?? 0,
              managerId,
              needReload: false,
            })
          }
        }
      },
    },
    selectedManagerType: {
      get () {
        return this.$store.state.user.selectedManagerType
      },
      async set (newValue) {
        const oldSelectedManagerType
          = this.$store.state.user.selectedManagerType
        const oldSelectedTenantId = this.$store.state.user.selectedTenantId

        // 새로운 값 설정
        this.$store.commit('user/setSelectedManagerType', newValue)
        window.localStorage.setItem('selected_manager_type', newValue)

        // 관리자 유형에 따른 입주 아이디 처리
        if (newValue === 'membership-manager') {
          // 어카운트 관리자 일 때 tenant id 초기화
          this.selectedTenantId = 0

          // 메뉴 조회
          const managerId
            = this.selectableAccountListByKey[this.selectedAccountId]?.[
              this.selectedManagerType
            ]?.[0].managerId

          if (oldSelectedManagerType === null) {
            await this.$store.dispatch('user/getMenu', {
              membershipId: window.localStorage.getItem('selected_account_id'),
              managerType: window.localStorage.getItem('selected_manager_type'),
              tenantId: window.localStorage.getItem('selected_tenant_id') ?? 0,
              managerId,
              needReload: false,
            })
          } else {
            await this.$store.dispatch('user/getMenu', {
              membershipId: window.localStorage.getItem('selected_account_id'),
              managerType: window.localStorage.getItem('selected_manager_type'),
              tenantId: window.localStorage.getItem('selected_tenant_id') ?? 0,
              managerId,
              needReload: true,
            })
          }
        } else if (newValue === 'tenant-manager') {
          // 첫 번째 입주사 자동 선택

          // 새로고침 했을 때
          if (this.isRefreshed) {
            this.selectedTenantId
              = window.localStorage.getItem('selected_tenant_id')
          } else {
            this.selectedTenantId = this.availableTenantList[0]?.tenantId || 0
          }
          // 입주사 아이디가 안바뀌면 여기서 메뉴 조회
          // 어카운트가 달라도 입주사가 같은 경우가 있어서 어카운트 변경 여부 확인
          if (
            this.isAccountIdChanged
            && this.selectedTenantId === oldSelectedTenantId
          ) {
            const managerId
              = this.selectableAccountListByKey[this.selectedAccountId]?.[
                this.selectedManagerType
              ]?.[0].managerId

            if (oldSelectedManagerType === null) {
              await this.$store.dispatch('user/getMenu', {
                membershipId: window.localStorage.getItem(
                  'selected_account_id',
                ),
                managerType: window.localStorage.getItem(
                  'selected_manager_type',
                ),
                tenantId:
                  window.localStorage.getItem('selected_tenant_id') ?? 0,
                managerId,
                needReload: false,
              })
            } else if (this.selectedTenantId !== oldSelectedTenantId) {
              await this.$store.dispatch('user/getMenu', {
                membershipId: window.localStorage.getItem(
                  'selected_account_id',
                ),
                managerType: window.localStorage.getItem(
                  'selected_manager_type',
                ),
                tenantId:
                  window.localStorage.getItem('selected_tenant_id') ?? 0,
                managerId,
                needReload: true,
              })
            }
          } else {
            // 입주사 아이디가 바뀌면 입주사 아이디에서 메뉴 조회
          }
        }

        // accountid + managertype 조합 바뀌면 menu 새로조회
      },
    },
    selectedTenantId: {
      get () {
        return this.$store.state.user.selectedTenantId
      },
      async set (newValue) {
        const oldSelectedTenantId = this.$store.state.user.selectedTenantId

        this.$store.commit('user/setSelectedTenantId', newValue)
        window.localStorage.setItem('selected_tenant_id', newValue)

        // 공백일 경우 어카운트 관리자 타입 변경 때 메뉴 조회했으므로, 공백이 아닐때만 메뉴 조회
        if (newValue !== '') {
          // 선택한 값의 manager id 추출해서 메뉴 조회 후 이동
          const managerId
            = this.selectableAccountListByKey[this.selectedAccountId]?.[
              this.selectedManagerType
            ]?.[0].managerId

          if (oldSelectedTenantId === 0) {
            await this.$store.dispatch('user/getMenu', {
              membershipId: window.localStorage.getItem('selected_account_id'),
              managerType: window.localStorage.getItem('selected_manager_type'),
              tenantId: window.localStorage.getItem('selected_tenant_id') ?? 0,
              managerId,
              needReload: false,
            })
          } else {
            await this.$store.dispatch('user/getMenu', {
              membershipId: window.localStorage.getItem('selected_account_id'),
              managerType: window.localStorage.getItem('selected_manager_type'),
              tenantId: window.localStorage.getItem('selected_tenant_id') ?? 0,
              managerId,
              needReload: true,
            })
          }
        }

        // 입주사 이미지 처리
        this.setTenantLogoImage()
      },
    },
    selectableAccountList () {
      return this.$store.state.user.selectableMembershipList
    },
    selectableAccountListByKey () {
      return this.$store.state.user.selectableMembershipListByKey // key 로 조회하기 위한 객체
    },
    isSetMembershipId () {
      return this.selectedAccountId !== 0
    },
    isShowTenantSelectBox () {
      // 어카운트에 대한 입주사 관리자 권한이 2개 이상 있을 때 노출
      // return this.availableTenantList.length > 1
      return this.availableTenantList.length > 0
    },
    tree () {
      return this.$store.getters.tree
    },
    userName () {
      return this.$store.getters['user/userName']
    },
    availableManagerTypeList () {
      return (
        this.selectableAccountListByKey[this.selectedAccountId]
          ?.managerTypeList || []
      )
    },
    availableTenantList () {
      return (
        (this.selectedManagerType !== 'membership-manager'
          && this.selectableAccountListByKey[this.selectedAccountId]?.[
            'tenant-manager'
          ])
        || []
      )
    },

    selectedMenuIndex () {
      return this.$store.state.user.selectedMenuIndex
    },
  },
  methods: {
    onSelected01 (item) {
      this.selectedTenantId = item.tenantId
      this.toggle01 = false
      this.toggle02 = false
      this.onSelected01TenantId(item)
    },
    onSelected01TenantId (event) {
      const selectedValue = event.tenantId
      if (this.$route.fullPath !== '/contract/status-inquiry') {
        this.selectedTenantId = selectedValue
        this.isToggleShow(0)
        this.$router.push('/contract/status-inquiry')
      }
      setTimeout(() => {
        this.$store.commit('user/setReload')
      }, 100)

    },

    onSelected02 (item) {
      this.toggle01 = false
      this.toggle02 = false
      if (item.membershipId === this.selectedAccountId) {
        return
      }
      this.selectedAccountId = item.membershipId
      this.onSelected02AccountId(item)
    },
    onSelected02AccountId (event) {
      const selectedValue = event.membershipId
      if (this.$route.fullPath !== '/contract/status-inquiry') {
        this.selectedAccountId = selectedValue
        this.isToggleShow(0)
        this.$router.push('/contract/status-inquiry')
      }
      setTimeout(() => {
        this.$store.commit('user/setReload')
      }, 100)

    },

    onChangeAccountId (event) {
      const selectedValue = event.target.value
      if (this.$route.fullPath !== '/contract/status-inquiry') {
        this.selectedAccountId = selectedValue
        this.isToggleShow(0)
        this.$router.push('/contract/status-inquiry')
      } else {
        setTimeout(() => {
          this.$store.commit('user/setReload')
        }, 100)

      }
    },
    moveMainPage () {
      this.$router.push({ name: 'ContractStatusInquiry' })
      this.$store.commit('user/setSelectedMenuIndex', 0)
    },
    onMenuShow () {
      this.isHiddenMenu = !this.isHiddenMenu
    },
    setTenantLogoImage () {
      if (this.selectedTenantId != 0) {
        const tenantInfo
          = this.availableTenantList.filter(
            (item) => item.tenantId == this.selectedTenantId,
          )[0] ?? {}
        if (tenantInfo.tenantImageUrl != null) {
          this.isTenantLogo = true
          this.tenantLogoImageUrl = tenantInfo.tenantImageUrl
        } else {
          this.isTenantLogo = false
        }
      } else {
        this.isTenantLogo = false
      }
    },

    isToggleShow (idx) {
      this.$store.commit('user/setSelectedMenuIndex', idx)
    },
  },

  watch: {
    tree (newValue) {
      // menu데이터 셋되면 반응
      if (!newValue && this.tree.length > 0) {
        return
      }
      // 새로고침 했을때 메뉴에서 최처 디폴트 메뉴 열려있게 해주는 기능
      const path = this.$route.path
      this.tree.forEach((i, idx) => {
        if (i.child.some((b) => b.menuUrl === path)) {
          this.isToggleShow(idx)
        }
      })
    },
    selectedAccountId (newValue) {
      // if (!newValue) {
      //   return
      // }

      // this.$store.commit('user/setReload')
    },
  },
}
</script>

<style lang="scss" scoped>
.navigation {
  padding: 105px 24px 100px 24px;
  height: 100%;
  overflow: auto;
  user-select: none;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 232px;
  box-sizing: border-box;
  background-color: #1e1e1e;
  color: #fff;

  .welcome-message {
    margin-bottom: 20px;
    color: #fff;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: -0.44px;
    line-height: normal;
    overflow: hidden;

    .ellipsis {
      display: flex;
      margin-bottom: 5px;
      span {
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 110px;
      }
    }
  }

  .custom-select{
    position:relative;
    display:flex;
    flex-direction: column;
    border-radius: 4px;
    background: var(--gray-80);
    &.show{
      border-radius:4px 4px 0px 0px ;
      .select-list{
        max-height: 240px; /* 펼쳐진 상태에서의 최대 높이 설정 (필요에 맞게 변경) */
      }
    }
    & + .custom-select{
      margin-top:10px;
    }
    .item{
        color: var(--gray-10);
        font-size: 13px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.2px;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .selected{
      position:relative;
      padding:0 12px;
      display:flex;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;
      height: 49px;
      strong{
        color: var(--gray-20);
        font-size: 11px;
        font-weight: 400;
        line-height: normal;
        margin-bottom:4px;
      }
      pointer-events: none;
      &.icon{
        pointer-events: auto;
        &::before {
          content:'';
          position: absolute;
          top: 15px;
          right: 10px;
          display: block;
          width: 16px;
          height: 16px;
          margin: 0;
          border: none;
          background-color: #F0F0F0;
          -webkit-mask: url('@/assets/images/icon-menu-select.svg') no-repeat 50% 50%;
          mask: url('@/assets/images/icon-menu-select.svg') no-repeat 50% 50%;
          transition: transform 0.3s ease;
        }
      }
      &.active::before {
        transform: rotate(180deg); /* 180도 회전 */
      }
    }

    .select-list{
      position:absolute;
      top:47px;
      left:0;
      width:100%;
      z-index:30;
      background: var(--gray-80);
      max-height: 0; /* 처음에는 숨겨진 상태 */
      overflow-y: auto; /* 콘텐츠 넘침을 숨김 */
      transition: max-height 0.3s ease; /* 트랜지션 효과 */
      border-radius: 0px 0px 4px 4px;
      li{
        border-top: 1px solid var(--gray-70);
        padding:0 10px;
        height: 47px;
        line-height:47px;
        cursor: pointer;
        &:hover{
          background: var(--gray-70);
        }
      }

    }

  }

  .logo {
    font-style: normal;
    font-weight: 800;
    font-size: 0px;
    // line-height: 44.2px;
    width: 100%;
    margin-bottom: 40px;
    cursor: pointer;

    img {
      width: 155px;
      height: 29px;
    }
  }

  //logo

  .user-info {
    position: relative;
    padding: 20px 20px 0 20px;
    overflow: hidden;
    cursor: pointer;
    background: var(--gray-90);

    .user-info-icon {
      position: absolute;
      top: 32px;
      right: 20px;

      &.on {
        transform: rotate(180deg);
      }
    }

    .user-info-title {
      margin-bottom: 16px;
      color: #fff;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: -0.36px;
      line-height: normal;
      overflow: hidden;

      .ellipsis {
        display: flex;
        margin-bottom: 5px;

        span {
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 110px;
        }
      }
    }

    .user-info-sub {
      display: flex;
      flex-direction: row;
      // max-width: 130px;
      color: #e3e3e3;
      font-size: 13px;
      font-weight: 400;
      line-height: normal;
      padding-bottom: 24px;

      i {
        font-style: normal;
        width: 10px;
        text-align: center;
      }

      span {
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: calc(50% - 5px);
      }
    }

    .hidden-menu {
      position: relative;
      cursor: default;

      > div + div {
        margin-top: 12px;
      }

      .label {
        display: block;
        color: #e3e3e3;
        font-size: 11px;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 8px;
      }

      /deep/ .selectbox {
        select {
          border: 0;
          border-radius: 7px;
          background-color: var(--gray-70);
          color: var(--gray-10);
          font-size: 15px;
          font-weight: 500;
          letter-spacing: -0.2px;
          padding-right: 20px;
        }
      }

      .btn-box {
        margin-top: 24px;
        position: relative;
        left: -24px;
        border-top: 1px solid var(--gray-80);
        background: var(--gray-90);
        width: calc(100% + 48px);
        height: 44px;

        .gap {
          width: 100%;
          height: 100%;

          /deep/ .btn-logout {
            background: none;
            border: 0;
            color: #e3e3e3;
            font-size: 13px;
            font-weight: 600;
            line-height: normal;
          }
        }
      }
    }
  }

  //user-info

  .main-navigation {
    margin: 40px 0 0 0;
    padding: 0;
    display: flex;
    flex-direction: column;

    > li {
      & + li {
        margin-top: 28px;
      }

      font-size: 18px;
      line-height: 20px;

      &.toggle {
        .icon-arrow {
          transform: rotate(90deg);
        }

        .sub-navigation {
          display: block;
        }

        .active {
          color: var(--theme-selected-color);
        }
      }

      .sub-navigation {
        display: none;
        padding: 16px 0 0 24px;

        > li {
          color: #e9e9e9;
          font-size: 15px;
          font-weight: 400;

          > a {
            display: inline-block;
            // color: #e9e9e9;
            color:#BCBCBC;
            // margin: 5px 0;
          }
        }

        > li + li {
          margin-top: 16px;
        }
      }

      > a {
        font-size: 20px;
        font-weight: 600;
        letter-spacing: -0.4px;
        color: #fff;
        display: inline-block;
        // margin: 40px 0 24px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
      }
    }
  }

  //main-navigation

  @media (max-width: 1000px) {
    flex-basis: 100%;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }
}

.btn-guide-box{
  margin-top:40px;
  button{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:0 20px 0 24px;
    width:100%;
    height:44px;
    border-radius: 7px;
    background: #353535;
    border:none;
    color: #E3E3E3;
    font-size: 13px;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
  }
}
</style>
