const getDefaultState = () => {
  return {
    list: [],
    count: 0,
    originFilter: {},
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    totalPages: state => {
      const limit = state.originFilter?.limit || 20
      const count = state.count || 0
      return Math.ceil(count / limit)
    },
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setResponse (state, data) {
      state.list = data.invoices
      state.count = data.totalCount
    },
    setOriginFilter (state, filter) {
      state.originFilter = JSON.parse(JSON.stringify(filter))
    },
  },
  actions: {
    // 입주사명 호출
    async getInvoices ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.SPARKONE_OFFICE_API_URL}office/invoices`, payload)

        commit('setResponse', response.data)
        commit('setOriginFilter', payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 청구월리스트 호출
    async getInvoiceBillingMonths ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.SPARKONE_OFFICE_API_URL}office/invoice-billing-months`, payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 어드민 청구서 다운로드 로그 기록
    async postInvoiceDownloadLog ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.post(`${this._vm.SPARKONE_OFFICE_API_URL}office/invoice/${payload.fileId}/download?membershipId=${payload.data.membershipId}&managerType=${payload.data.managerType}&tenantId=${payload.data.tenantId}&managerId=${payload.data.managerId}`)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // // 청구월 호출
    // async getInvoiceMonth ({ commit }, payload) {
    //   let response = null
    //   try {
    //     response = await this._vm.get(`${this._vm.SPARKONE_OFFICE_API_URL}office/settlement/common/filter/billing-year-month`, payload)
    //     // response = await this._vm.get('../src/store/modules/invoice/testInvoiceMonth.json', payload)
    //   } catch (error) {
    //     if (error.response) {
    //       response = error
    //     } else {
    //       throw error
    //     }
    //   }
    //   return response
    // },

  },
}
