<template>
  <div class="container">
    <div class="content">
      <h1>
        <img
          style="max-width: 70px"
          src="../../../assets/icon/icon-animation-03.gif"
          alt=""
        />
      </h1>
      <div class="big-text">
        {{ detail.title }}
      </div>
      <div class="sub-text">
        {{ detail.message }}
      </div>
      <div class="maintenance-notice">
        <!-- 12/23 00:00 ~ 12/25 00:00 -->
        {{ transformDetail(detail.detail) }}
      </div>
    </div><!-- //content -->

    <div class="footer-text">
      by SPARKPLUS
    </div>

  </div>
</template>

<script>
export default {
  name: 'ErrorMaintenance',
  props: {
    data: {
      type: Object,
      default: Object,
    },
  },
  data () {
    return {
      detail: {
        title: '',
        message: '',
        detail: '',
      },
    }
  },
  created () {
    this.detail = this.data
  },
  mounted () {
  },
  // 로그인 시도
  methods: {
    transformDetail (detail) {
      return detail.replace(/\./g, '/').replace(/-/g, '~')
    },
  },
  computed: {
  },
}
</script>
<style lang="scss" scoped>
.container{
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index: 999999;

  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:100%;
  height: 100%;
  box-sizing: border-box;
  background: var(--yellow-10);
  .content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 375px;
    height: 100%;
    h1{
        margin-bottom:40px;
        font-size: 0;
    }
    .big-text{
      margin-bottom:16px;
      color: #000;
      font-size: 22px;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.4px;
    }
    .sub-text{
      margin-bottom:40px;
      color: #626262;
      font-family: "Noto Sans";
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: -0.4px;
      white-space: pre-line;
    }
    .maintenance-notice {
      color: var(--gray-100);
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      line-height: 23px;
    }
  }

  .footer-text{
    padding-top:20px;
    margin:auto 0 56px 0;
    color: var(--gray-40, #A5A5A5);
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
  }

}
.button-group{
  /deep/ button{
    width:166px;
  }
}
@media only screen and (min-width: 640px) {
  //가로값이 640이하에서 적용
  .button-group{
    /deep/ button{
      width:200px;
    }
  }
}
</style>
