import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'
import usageRoutes from '@/router/usageRoutes'
import settlementRoutes from '@/router/settlementRoutes'

const TenantList = () => import('@/components/tenant/information/List')
const TenantForm = () => import('@/components/tenant/information/Form')
const ManagerList = () => import('@/components/manager/List')
const managerForm = () => import('@/components/manager/Form')
const Error404 = () => import('@/components/common/error/Error404')
const Error403 = () => import('@/components/common/error/Error403')
const Main = () => import('@/components/Main')
const Login = () => import('@/components/login/Login')
const Recovery = () => import('@/components/recovery/Recovery')
const RecoveryId = () => import('@/components/recovery/RecoveryId')
const RecoveryPassword = () => import('@/components/recovery/RecoveryPassword')
const DepartmentPosition = () => import('@/components/tenant/DepartmentPosition')

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'Error404',
    component: Error404,
    meta: {
      requiresAuth: false,
      fullScreen: true,
    },
  },
  {
    path: '*',
    name: 'Error403',
    component: Error403,
    meta: {
      requiresAuth: false,
      fullScreen: true,
    },
  },
  {
    path: '/',
    name: 'Main',
    component: Main,
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  // #####점검 안내 페이지#####
  // {
  //   path: '/error-maintenance',
  //   name: 'ErrorMaintenance',
  //   component: () => import('@/components/common/error/ErrorMaintenance.vue'),
  //   meta: {
  //     requiresAuth: false,
  //     fullScreen: true,
  //   },
  // },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false,
      fullScreen: true,
      mode: 'login',
      redirect: '/tenant/tenant-business',
    },
  },
  {
    path: '/login/reset/:token',
    name: 'LoginNewPassword',
    component: Login,
    meta: {
      requiresAuth: false,
      fullScreen: true,
      mode: 'newPassword',
      redirect: '/',
    },
  },
  {
    path: '/recovery/id',
    name: 'RecoveryId',
    component: RecoveryId,
    meta: {
      requiresAuth: false,
      fullScreen: true,
    },
  },
  {
    path: '/recovery/password',
    name: 'RecoveryPassword',
    component: RecoveryPassword,
    meta: {
      requiresAuth: false,
      fullScreen: true,
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Login,
    meta: {
      requiresAuth: false,
      fullScreen: true,
    },
    beforeEnter: async (to, from, next) => {
      let result
      if (await store.dispatch('user/refresh')) {
        result = await store.dispatch('user/logout')
      }
      if (result == 'error') {
        alert('로그아웃에 실패 했습니다. 인터넷 연결을 확인 해 주세요.')
        next(from.fullPath)
      } else {
        if (to.query.returnPage) {
          next({
            path: '/login',
          })
        } else {
          next('/login')
        }
      }
    },
  },
  {
    // 입주사 정보
    path: '/tenant/info',
    name: 'TenantList',
    component: TenantList,
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    // 입주사(사업자) 정보 상세
    path: '/tenant/info/business/:id/detail',
    name: 'TenantBusinessDetail',
    component: TenantForm,
    meta: {
      mode: 'business',
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    // 입주사(개인) 정보 상세
    path: '/tenant/info/personal/:id/detail',
    name: 'TenantPersonalDetail',
    component: TenantForm,
    meta: {
      mode: 'personal',
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    // 입주사 관리자 리스트
    path: '/tenant/tenant-manager',
    name: 'TenantManagerList',
    component: ManagerList,
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    // 입주사 관리자 상세
    path: '/tenant/tenant-manager/:id/detail',
    name: 'TenantManagerDetail',
    component: managerForm,
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/tenant/department-position',
    name: 'DepartmentPosition',
    component: DepartmentPosition,
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/contract/information',
    name: 'ContractInformation',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/contract/ContractInformation.vue'),
  },

  // OFFICE-B 계약관리 > 계약 현황 조회
  {
    path: '/contract/status-inquiry',
    name: 'ContractStatusInquiry',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/contract_v2/ContractStatusInquiry.vue'),
  },
  // OFFICE-B 계약관리 > 월 청구서
  {
    path: '/contract/monthly-billing',
    name: 'ContractMonthlyBilling',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/contract_v2/ContractMonthlyBilling.vue'),
  },
  // OFFICE-B 멤버관리 > 멤버정보
  {
    path: '/member/member-infoList',
    name: 'MemberInfoList',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/member/MemberInfoList.vue'),
  },
  // OFFICE-B 멤버관리 > 멤버등록
  {
    path: '/member/member-registration',
    name: 'MemberRegistration',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/member/MemberRegistration.vue'),
  },
  // OFFICE-B 멤버관리 > 팀설정
  {
    path: '/member/member-departments',
    name: 'MemberDepartments',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/member/MemberDepartments.vue'),
  },
  // OFFICE-B 멤버관리 > 팀 신규 등록
  {
    path: '/member/member-departments-registration',
    name: 'MemberDepartmentsRegistration',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/member/MemberDepartmentsRegistration.vue'),
  },

  // OFFICE-B 관리자 설정 > 관리자 정보
  {
    path: '/manager/manager-infoList',
    name: 'ManagerInfoList',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/manager_v2/ManagerInfoList.vue'),
  },

  // OFFICE-B 관리자 설정 > 관리자 등록
  {
    path: '/manager/manager-registration',
    name: 'ManagerRegistration',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/manager_v2/ManagerRegistration.vue'),
  },

  // OFFICE-B 회원가입/이용약관
  {
    path: '/signup',
    name: 'Signup',
    meta: {
      requiresAuth: false,
      fullScreen: true,
    },
    component: () => import('@/components/login/Signup.vue'),
  },

  // OFFICE-B 아이디/비밀번호 찾기
  {
    path: '/recoverypage',
    name: 'Recovery',
    component: Recovery,
    meta: {
      requiresAuth: false,
      fullScreen: true,
    },
  },

  // OFFICE-B 워크오더 > 공지 조회 리스트
  {
    path: '/workorder/notice',
    name: 'NoticeList',
    component: () => import('@/components/workorder/notice/List.vue'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  // OFFICE-B 워크오더 > 공지 조회 리스트 상세보기
  {
    path: '/workorder/notice/:id/detail',
    name: 'NoticeDetail',
    component: () => import('@/components/workorder/notice/Detail.vue'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },

  // OFFICE-B 워크오더 > 요청 관리 리스트
  {
    path: '/workorder/order-list',
    name: 'OrderList',
    component: () => import('@/components/workorder/order/List.vue'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },

  // OFFICE-B 워크오더 > 요청 관리 리스트 상세보기
  {
    path: '/workorder/order-list/:id/detail',
    name: 'OrderDetail',
    component: () => import('@/components/workorder/order/Detail.vue'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },

  // OFFICE-B 워크오더 > 요청 등록 페이지
  {
    path: '/workorder/order-registration',
    name: 'OrderRegistration',
    component: () => import('@/components/workorder/order/Registration.vue'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },

  // OFFICE-B 미팅룸 관리 > 미팅룸 조회 페이지
  {
    path: '/space/meeting-room',
    name: 'SpaceMeetingRoomList',
    component: () => import('@/components/space/List.vue'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  // OFFICE-B 미팅룸 관리 > 미팅룸 등록 페이지
  {
    path: '/space/meeting-room-registration',
    name: 'SpaceMeetingRoomRegistration',
    component: () => import('@/components/space/Registration.vue'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },

  // OFFICE-B IOT 설정 > 기기 조회 페이지
  {
    path: '/space/iot-list',
    name: 'IotDeviceList',
    component: () => import('@/components/iot/device/List.vue'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },

  // OFFICE-B IOT 설정 > 자동화 조회 페이지
  {
    path: '/space/iot-automation-list',
    name: 'IotAutomationList',
    component: () => import('@/components/iot/automation/List.vue'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  // OFFICE-B IOT 설정 > 자동화 등록 페이지
  {
    path: '/space/iot-automation-registration',
    name: 'IotAutomationRegistration',
    component: () => import('@/components/iot/automation/Registration.vue'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  // OFFICE-B IOT 설정 > 자동화 변경 페이지
  {
    path: '/space/iot-automation/:id/detail',
    name: 'IotAutomationDetail',
    component: () => import('@/components/iot/automation/Registration.vue'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },

  // OFFICE-B 앱 이용내역 > 미팅룸 예약내역 페이지
  {
    path: '/usage/meetring-room-reservation',
    name: 'MeetringRoomReservationHistory',
    component: () => import('@/components/usage_v2/MeetringRoomReservationHistory.vue'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  // OFFICE-B 앱 이용내역 > 방문자 초대내역 페이지
  {
    path: '/usage/guest-invitation',
    name: 'GuestInvitationHistory',
    component: () => import('@/components/usage_v2/GuestInvitationHistory.vue'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  // OFFICE-B 앱 이용내역 > 출퇴근 내역 페이지
  {
    path: '/attendance/list',
    name: 'AttendanceHistory',
    component: () => import('@/components/usage_v2/AttendanceHistory.vue'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  // OFFICE-B 내정보 페이지
  {
    path: '/member/profile',
    name: 'MemberMyInfo',
    component: () => import('@/components/member/MemberProfile.vue'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  // OFFICE-B 내정보 페이지 > 휴대전화 번호 변경 페이지
  {
    path: '/member/profile/phone-number-change',
    name: 'PhoneNumberChange',
    component: () => import('@/components/member/profile/PhoneNumberChange.vue'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  // OFFICE-B 내정보 페이지 > 비밀번호 변경 페이지
  {
    path: '/member/profile/password-change',
    name: 'PasswordChange',
    component: () => import('@/components/member/profile/PasswordChange.vue'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  // OFFICE-B 내정보 페이지 > 회원 탈퇴 페이지
  {
    path: '/member/profile/membership-withdrawal',
    name: 'MembershipWithdrawal',
    component: () => import('@/components/member/profile/MembershipWithdrawal.vue'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },

  {
    path: '/tenant/account-member',
    name: 'AccountMemberPage',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/tenant/member/MemberPage.vue'),
  },
  {
    path: '/tenant/account-member/:id/edit',
    name: 'AccountMemberEditPage',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/tenant/member/MemberEditPage.vue'),
  },
  {
    path: '/settlement/list',
    name: 'InvoiceList',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/invoice/InvoiceList.vue'),
  },
  {
    path: '/settlement/monthly',
    name: 'InvoiceMonthly',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/invoice/InvoiceMonthly.vue'),
  },
  {
    path: '/settlement/unpaid',
    name: 'InvoiceUnpaid',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/invoice/InvoiceUnpaid.vue'),
  },
  ...usageRoutes,
  ...settlementRoutes,
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

// 엑세스 토큰 보유여부 확인
const isLogined = function () {
  return !!store.state.user.accessToken
}

// 네비게이션 가드
router.beforeEach(async (to, from, next) => {
  // 진행중이던 axios 모두 취소
  store.dispatch('cancelPendingRequests')
  // 로그인이 필요한 페이지여부 확인
  if (to.matched.some(route => route.meta.requiresAuth)) {
    // 로그인 여부 확인
    if (isLogined()) {
      next()
      // 입주사 어드민은 권한이 확인된 메뉴만 뿌려주므로 진입 시 개별 페이지에 대한 접근 확인 필요 없음
    } else {
      // 엑세스 토큰 없음, 리프레시 토큰 갱신 시도
      if (await store.dispatch('user/refresh')) {
        // 리프레시 시도 성공
        await store.dispatch('user/getSelectableAccountList', { userId: store.getters['user/userId'] })
        next()
        // 페이지 접근 권한 확인 안함
      // 리프레시 실패 (토큰 없음 or 만료 or 인증서버 로직 에러)
      } else {
        // 로그인 페이지로 리다이렉트
        next({
          path: '/login',
        })
      }
    }

  // 권한이 필요 없는 페이지
  } else {
    // await store.dispatch('user/refresh')
    next()
  }
})

export default router
