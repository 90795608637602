<template>
  <div :style="{display:display}" v-if="isShow">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'SpAuthWrap',
  inheritattrs: false,
  props: {
    functionType: { type: String, default: '' },
    display: {
      type: String,
      default: 'inline-block',
    },
  },
  data () {
    return {
      uuid: null,
    }
  },
  computed: {
    isShow () {
      return this.$store.getters.pathToMenu(this.$route.path)?.functionType.indexOf(this.functionType) !== -1
    },
  },
  created () {

  },
  methods: {

  },
}
</script>
<style lang="scss" scoped>

</style>
