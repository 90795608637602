<template>
  <div class="datepicker-wrap" :id="componentId">
    <div class="filter-days">
      <sp-button
      class="gap picker"
      type="button"
      :class="{'gray-100': JSON.stringify(day.date) === JSON.stringify(dates)}"
      v-for="(day, i) in days"
      :key="i+componentKey"
      @click.prevent="setDate(day.date);isCalendarOn = false;">
        {{day.name}}
      </sp-button>
      <!-- customDates.length > 1 -->
      <div class="text-dates" :class="{ on : isCalendarOn }" v-show="picker && (dates && dates.length > 0)">
        <span v-for="(date,idx) in customDates" :key="idx" @click="isCalendarOpen = !isCalendarOpen">
        {{ formatISODate(date, "yyyy.mm.dd") }}
        </span>

        <!-- 달력 -->
        <div class="calendar-wrap" v-show="isCalendarOpen">
          <div class="calendar-head">
            <sp-button class="btn-calendar-close" type="button" style="min-width:16px;height:16px;" @click="isCalendarOpen = false">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5066 4.48297L13.0016 3.988L12.0117 2.99805L11.5167 3.49302L8.00013 7.00958L4.48357 3.49302L3.98859 2.99805L2.99864 3.988L3.49362 4.48297L7.01018 7.99953L3.49302 11.5167L2.99805 12.0117L3.988 13.0016L4.48297 12.5066L8.00013 8.98948L11.5173 12.5066L12.0123 13.0016L13.0022 12.0117L12.5072 11.5167L8.99008 7.99953L12.5066 4.48297Z" fill="#1E1E1E"/>
              </svg>
            </sp-button>
            <span class="notice-text" v-if="Math.round(lastDay.day / 30) > 0">
              *최대 {{ Math.round(lastDay.day / 30) }}개월 범위 내의 데이터만 조회 가능합니다
            </span>
          </div><!-- //calendar-head -->
          <div class="calendar-body">
            <v-date-picker
              @dayclick="onDayClick"
              v-model="calendar.value"
              :available-dates="range"
              :masks="calendar.masks"
              title-position="left"
              is-range
            >
            </v-date-picker>
          </div><!-- //calendar-body -->
        </div>
        <!-- //달력 -->

      </div>
    </div>

    <!-- <datepicker
    v-show="picker"
    placeholder="전체 기간"
    :circle="true"
    v-model="dates"
    lang="ko"
    firstDayOfWeek="sunday"
    :range="true"
    class="f-datepicker"
    @input="$emit('input', toISOStringDates)"
    ref="dp"
    /> -->

  </div>
</template>

<script>
import 'vue-datepicker-ui/lib/vuedatepickerui.css'
import VueDatepickerUi from 'vue-datepicker-ui'

export default {
  name: 'SpRangeDatepicker',
  props: [
    'value',
    'filterDays',
    'id',
    'picker',
    'range',
  ],
  data () {
    return {
      isCalendarOn: false,
      isCalendarOpen: false, // 달력 열기/닫기
      calendar: {
        value: {
          start: null,
          end: null,
        },
        masks: {
          input: 'YYYY-MM-DD h:mm A',
        },
        clickArray: [],
        attributes: [], // 필요한 경우 추가
      },

      componentKey: '',
      dates: [],
      days: [],
      uuid: null,

    }
  },
  created () {
    this.init()
  },
  mounted () {
    // this.setDate(this.dates)
    // console.log(this.$refs.dp.$el.firstChild.firstChild)
    // if (this.$refs.dp.$el.firstChild?.firstChild?.placeholder) {
    //   this.$refs.dp.$el.firstChild.firstChild.id = this.uuid
    // }
    const filterArea = this.$el.querySelector('.filter-days').getClientRects()
    if (filterArea) {
      this.$el.style.setProperty('--dynamic-width', `${310 + filterArea[0].width}px`)
    }
  },
  computed: {
    lastDay () {
      return this.filterDays[this.filterDays.length - 1]
    },

    // customDates = 오늘을 클릭할경우 시작날짜 - 끝날짜가 동일하기에 ui상 하나만 노출하자고 정책이 정해져서 시작날짜dates[0] - 끝날짜dates[1] 동일할경우 시작날짜dates[0]만 노출되게 만든 함수
    customDates () {
      const result = []
      if (this.dates) {
        for (let i = 0; i < this.dates.length; i++) {
          if (i > 0 && this.dates[i] === this.dates[i - 1]) {
            break // 루프를 중단
          }
          result.push(this.dates[i])
        }
      }
      return result
    },

    componentId () {
      if (this.id) {
        return this.id
      }
      return this.uuidv4()
    },
    toISOStringDates () {
      return this.dates.map(day => {
        if (day instanceof Date) {
          return day.toISOString()
        } else {
          return day
        }
      })
    },
    attrs () {
      return this.$attrs
    },
  },
  methods: {
    // 달력에서 날짜 클릭시
    async onDayClick (day) {
      if (this.calendar.clickArray.length === 2) {
        this.calendar.clickArray = [day.date]
      } else if (this.calendar.clickArray.length === 1) {
        this.isCalendarOn = true
        this.calendar.clickArray.push(day.date)
        this.dates = this.calendar.clickArray.sort((a, b) => new Date(a) - new Date(b))
        this.setDate(this.toISOStringDates)
      } else {
        this.calendar.clickArray.push(day.date)
      }
    },

    forceRerender () {
      this.componentKey = this.uuidv4()
    },
    init () {
      if (this.value instanceof Array) {
        this.dates = this.value
      }

      if (this.filterDays instanceof Array) {
        this.filterDays.forEach(el => {

          const dateTo = new Date(new Date().setHours(0, 0, 0, 0))
          const dateFrom = new Date(new Date().setHours(0, 0, 0, 0))
          let day = null
          if (el.day === 'all') {
            day = {
              name: el.name,
            }
          } else if (el.day > 0) {
            dateTo.setDate(dateTo.getDate() - el.day + 1)
          } else {
            dateFrom.setDate(dateFrom.getDate() - el.day - 1)
          }
          if (el.day !== 'all') {
            day = {
              name: el.name,
              date: [
                dateTo.toISOString(),
                dateFrom.toISOString(),
              ],
            }
          }

          if (el.day == 0) day.date = [null, null]

          if (el.default && this.dates.length == 0) {
            this.dates = day.date
          }
          this.days.push(day)
        })
      }
      if (this.dates) {
        if (this.dates.length == 0) {
          this.dates = [null, null]
        }
      }

    },
    setDate (date) {
      this.dates = date
      this.$emit('input', this.dates)
      this.$emit('filterDays')
    },
    reset () {
      this.filterDays.forEach(el => {
        if (el.default) {
          const dateTo = new Date(new Date().setHours(0, 0, 0, 0))
          const dateFrom = new Date(new Date().setHours(0, 0, 0, 0))
          if (el.day > 0) {
            dateTo.setDate(dateTo.getDate() - el.day + 1)
          } else {
            dateFrom.setDate(dateFrom.getDate() - el.day - 1)
          }
          const day = {
            name: el.name,
            date: [
              dateTo.toISOString(),
              dateFrom.toISOString(),
            ],
          }
          if (el.day == 0) day.date = [null, null]

          this.dates = [...day.date]
          this.forceRerender()
        }
      })
    },
  },
  watch: {
    value (newValue, prevValue) {
      if (!newValue) {
        return
      }
      // console.log('value', newValue)
      if (JSON.stringify(newValue) === JSON.stringify(prevValue)) return
      this.dates = this.value
    },
    dates (newValue, prevValue) {
      if (!newValue) {
        return
      }
      this.calendar.value = {
        start: newValue[0],
        end: newValue[1],
      }
    },
  },
  components: {
    Datepicker: VueDatepickerUi,
  },
}
</script>
<style lang="scss">
:root {
  --v-calendar-active-bg-color: var(--theme-primary-color);
  --v-calendar-datepicker-icon-color: var(--theme-primary-color);
  --v-calendar-range-bg-color: #edf0ff;
  --dynamic-width: 300px;
  --v-calendar-input-bg-disable-color: #f9f9f9;
}

.datepicker-wrap{
  text-align: left;
  position: relative;
  z-index: 2;
  container-name: datepicker;
  container-type: inline-size;
  // container-type: size;
  // position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: var(--dynamic-width);
  min-width: 300px;
  max-width: 100%;
  vertical-align: top;
  margin: 0;
}

  @media screen and (max-width: 1100px) {
  .v-calendar.f-datepicker .content {
    flex-direction: column !important;
    transform: translate(-10%, -17%) scale(73%) !important;
  }
}

.v-calendar.f-datepicker {
  display: inline-block;
  // margin: 4px 2.5px;
  margin-left:7px;
}
.v-calendar .number {
  margin: -2px 0 0 -5px;
}
.v-calendar .button{
  border-radius: 0;
  margin: 0;
  line-height: 10px;
  font-size: 20px !important;
}
.v-calendar.f-datepicker .content {
  flex-direction: row ;
  transform: translate(-11%, -20%) scale(73%);
}
.v-calendar.f-datepicker {
  display :inline-block;
  .input-field.long {
    min-width: 243px;
    input {
      color: #000;
      border-radius: 5px;
      height: 32px;
      border:none;
      padding: 1px 10px 1px 10px;
      box-sizing: border-box;
      background: #F1EDE1;
      cursor: pointer;
    }
    input:focus {
      border-color: var(--theme-primary-color);
      animation: shadow 0.1s ease-in-out forwards;
    }
    @keyframes shadow {
      to {
        box-shadow: 0 0 6px var(--theme-primary-color);
      }
    }
  }
  svg.datepicker{
    display:none;
  }
}
.filter-days {
  // display: inline-block;
  display:flex;
  vertical-align: top;
  > div + div {
    margin-left:6px;
  }
  //  margin: 4px 0;
  // margin: 0 4px;

  .button {
    padding:0 12px;
    border: 1px solid var(--gray-30);
    background:#F8F7EF;
    // margin: 0;
  }
}
</style>
<style lang="scss" scoped>
.button-days {
  font: 500 14px NanumSquareAc;
  cursor: pointer;
  /* vertical-align:top; */
  margin: 0 0 0 4px;
  height:26px;
  border-radius:5px;
  border:1px solid #ddd;
  background-color: #fff;
  background-image: linear-gradient(#fff, #f0f0f0);
  box-shadow: inset 0 1px 1px rgba(255,255,255,0.35),
              inset 0 -1px 1px rgba(0,0,0,0.35);
  line-height:14px;
}
/deep/ .picker {
  height:33px;
  border-radius: 20px;
}

.text-dates{
  position: relative;
  margin-left:10px;
  display: inline-block;
  padding:0 12px;
  height:33px;
  line-height:33px;
  border-radius: 7px;
  border: 1px solid var(--gray-30, #BCBCBC);
  box-sizing: border-box;
  color:var(--gray-100);
  span{
    cursor: pointer;
  }
  span + span::before{
    content: '-';
  }
  &.on{
    border-color:var(--gray-100);
    color:#fff;
    background: var(--gray-100);
  }
}

.calendar-wrap{
      position: absolute;
      top: 42px;
      left: 0;
      width:328px;
      background:#fff;
      border-radius: 4px;
      border: 1px solid var(--gray-20);
      box-sizing: border-box;
      .calendar-head{
        padding:0 10px;
        display:flex;
        flex-direction:row;
        align-items: center;
        justify-content: space-between;
        height:48px;
        /deep/ .btn-calendar-close{
          border: none;
          background:none;
          width:16px;
          height:16px;
          padding:0;
          margin:0;
        }
        .notice-text{
          color: var(--Primary);
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: -0.2px;
        }
      }
      .calendar-body{
        /deep/ .vc-container{
          border:none;
          border-radius:0;
          width: 100%;
          .vc-header{
            padding:10px;
            .vc-title{
              color: #1E1E1E;
              font-size: 12px;
              font-weight: 600;
              line-height: normal;
              letter-spacing: -0.2px;
              &:hover{
                opacity: 1;
              }
            }
          }
          .vc-arrows-container{
            padding:0;
            .vc-arrow{
              width:36px;
              height:36px;
              .vc-svg-icon{width:17px;height:17px;color:#A4A4A4;}
              &:hover{
                background: none;
                .vc-svg-icon{color:#1E1E1E;}
              }
            }
          }
          .vc-weeks{
            padding: 0 5px;
            .vc-weekday{
              padding: 4px 0;
              color:#1E1E1E;
              font-size: 12px;
              font-weight: 400;
              line-height: normal;
              letter-spacing: -0.2px;
            }
            .vc-day.is-not-in-month *{
              opacity: 1;
              color:#A4A4A4;
            }
            .vc-day{
              min-height: 44px;
              &.is-today *{
                color:#F37324;
              }
            }
            .vc-highlight{
              border:none !important;
              background-color: #F37324 !important;
            }
            .vc-day-layer + .vc-day-box-center-center .vc-highlight{
              width:30px;
              height:30px;
              background-color: #F37324 !important;
              border:0;
            }
            .vc-day-layer{
              .vc-highlight-base-start,.vc-highlight-base-middle,.vc-highlight-base-end{
                background:#fff2e9 !important;
              }
            }

            // .vc-highlights + .vc-day-content{
            //   color:#fff !important;
            // }

            .vc-day-content{
              color:#1E1E1E;
              font-size: 12px;
              font-weight: 400;
              line-height: normal;
              letter-spacing: -0.2px;
              &.is-disabled{
                color:#A4A4A4;
                pointer-events: none;
              }
              &:focus {
                background:none;
              }
              &:hover{
                background:none !important;
              }
            }
          }
        }

      }

    }
</style>
