<template>
  <div class="popup-wrap">
    <div @click.self="close('outside')" class="bg"></div>
    <div class="popup-container" ref="popup">
      <button class="btn-close" @click.prevent.stop="close('outside')">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M21.293 6.70726L22.0001 6.00015L20.5858 4.58594L19.8787 5.29304L14.5858 10.5859L9.29295 5.29304L8.58585 4.58594L7.17163 6.00015L7.87874 6.70726L13.1716 12.0002L7.87874 17.293L7.17163 18.0002L8.58584 19.4144L9.29295 18.7073L14.5858 13.4144L19.8787 18.7073L20.5858 19.4144L22.0001 18.0002L21.293 17.293L16.0001 12.0002L21.293 6.70726Z" fill="#1E1E1E"/>
        </svg>
      </button>

      <div v-show="title" class="popup-title" >
        <span>{{ title }}</span>
        <slot name="button"></slot>
      </div>
      <div class="popup-content">
        <div v-show="description" class="description" v-html="description"></div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Popup',
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    closeOnClickOutside: {
      type: Boolean,
      default: true,
    },
  },
  mounted () {
    if (this.$refs.popup.querySelector('button:last-child')) {
      this.$refs.popup.querySelector('button:last-child').focus()
    }
    // this.scrollToTop()
  },
  methods: {
    close (from) {
      if (from == 'outside' && this.closeOnClickOutside) {
        this.$emit('close')
      }
    },
    scrollToTop () {
      const popupContainer = this.$el.querySelector('.popup-container')
      if (popupContainer) {
        popupContainer.scrollTop = 0
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.bg {
  position: fixed;
  top: 0;
  left:0;
  right: 0;
  bottom: 0;
  opacity: 0.3;
  background: var(--gray-100);
  z-index: 101;
}
.popup-wrap {
  position: fixed !important;
  height: 100vh;
  top: 0;
  left:0;
  right: 0;
  bottom: 0;
  z-index: 102;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-container {
  position:relative;
  box-sizing: border-box;
  width:100%;
  z-index: 103;
  min-width: 100px;
  max-height: 100%;
  overflow-y: auto;
  // width: fit-content;
  height: fit-content;
  max-width: 440px;
  background: #F8F7EF;
  .btn-close{
    position: absolute;
    top: 16px;
    right: 20px;
    z-index: 2;
    padding: 0;
    margin: 0;
    border: 0;
    background: none;
    font-size: 0;
    cursor: pointer;
  }
  .popup-title {
    position:relative;
    width:100%;
    height:56px;
    padding:0 20px;
    box-sizing: border-box;
    line-height:56px;
    color:var(--gray-100);
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.3px;
    border-bottom: 1px solid var(--gray-20);
    span{
      white-space: pre-line;
    }
  }
  .popup-content{
    padding:30px 20px 40px 20px;
  }
  .description {
    font-size: 15px;
    color: #888899;
    white-space: pre-line;
    // padding-bottom: 10px;
  }
  .button-group {
    margin-top:40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
</style>
