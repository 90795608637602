<template>
  <div class="range-slider-wrap">
    <div class="bg-box">
      <div class="bg">
        <span class="selected-bg" :style="{ width: percentage + '%' }"></span>
      </div>
      <input
        ref="rangeInput"
        :value="value"
        type="range"
        class="rangeInput"
        :class="{ 'left': `${value}` === `0`, 'right': `${value}` === `${max}` }"
        min="0"
        :max="max"
        :step="step"
        @input="updateValue($event.target.value)"
      />
      <span v-for="number in numbers" :key="number" class="line" :style="{ left: `${(number / max) * 100}%` }"></span>
    </div><!-- //bg-box -->
    <div class="text-wrap">
      <span v-for="number in numbers" :key="number" class="item" :style="{ left: `calc(${(number / max) * 100}% - 2px)` }">
        {{ number }}
      </span>
    </div><!-- //text-wrap -->
  </div><!-- //range-slider-wrap -->
</template>

<script>

export default {
  name: 'SpInputRange',
  components: {
  },
  data () {
    return {
    }
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: null,
    },
    step: {
      type: Number,
      default: null,
    },
  },
  computed: {
    percentage () {
      return (this.value / this.max) * 100
    },
    numbers () {
      const arr = []
      for (let i = 0; i <= this.max; i += this.step) {
        arr.push(i)
      }
      // 추가 반복
      if (arr[arr.length - 1] !== this.max) {
        arr.push(this.max)
      }
      return arr
    },

  },
  watch: {
    // 팝업 열릴때 감시
    isOpen (newValue) {
      if (!newValue) {
        // console.log('isOpen', newValue)

      }

    },

  },
  methods: {
    updateValue (newValue) {
      this.$emit('input', Number(newValue))
    },
  },
  mounted () {},
}
</script>

<style lang="scss" scoped>
.range-slider-wrap{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 50px;
    position: relative;
    &.loading{
      .selected-bg{
        background: var(--theme-gray-50) !important;
      }
    }
    .bg-box{
        padding-top:10px;
        position: relative;
        width:100%;
        height:100%;
        z-index: 5;
        .bg{
          border-radius: 4px;
          width:100%;
          height:8px;
          background-color:#e5e5e5;
          .selected-bg{
            display: block;
            height: 100%;
            border-radius: 4px;
            background: var(--primary, #F37324);
          }
        }

        //line 눈금 스타일
        .line{
            position:absolute;
            top:10px;
            left:0%;
            width:1px;
            height:8px;
            opacity: 0.1;
            background: #1E1E1E;
            z-index: 3;
            display:none;
            & + .line{
                display:block;
            }
            &:last-child{
                display:none;
            }
        }
        //rangeInput 인풋 자체 스타일
        .rangeInput{
            position:absolute;
            // top:-11px;
            top:10px;
            left:0;
            width: calc(100% + 28px);
            margin-left:-14px;
            height: 8px;
            // height: 100%;
            background-color:transparent;
            outline: none;
            transition: background 450ms ease-in;
            appearance: none;
            -webkit-appearance: none;
        }
        //rangeInput::-webkit-slider-thumb 인풋 자체의 동그라미 버튼 스타일
        .rangeInput::-webkit-slider-thumb {
            -webkit-appearance: none; /* 기본 CSS 스타일을 적용하지 않기 위해서 */
            appearance: none; /* 기본 CSS 스타일을 적용하지 않기 위해서 */
            width: 28px; /* 슬라이더 핸들 길이 */
            height: 28px; /* 슬라이더 핸들 높이 */
            border-radius: 50%; /* 핸들 모양을 원모양으로 만들기 위함 */
            background: #fff; /* 슬라이더 핸들 색상 */
            cursor: pointer; /* 슬라이더 핸들에 마우스를 갖다대면 포인터로 변경 */
            box-shadow: 0px 5px 10px #cdcdcd;
            position: relative;
            z-index: 10;
        }
        //현재 상태값이 0인경우 동그라미 버튼 가운데 정렬이 아니라 왼쪽 정렬 해야하기에 추가로 준 스타일
        .left::-webkit-slider-thumb {
            margin-left:12px;
        }
        //현재 상태값이 오른쪽 끝인경우 동그라미 버튼 가운데 정렬이 아니라 오른쪽 정렬 해야하기에 추가로 준 스타일
        .right::-webkit-slider-thumb {
            margin-left:-12px;
        }
    }//bg-box

    //text-wrap 하단에 눈금이랑 같은 위치에 눈금의 숫자 정보 표시영역 스타일
    .text-wrap{
        box-sizing: border-box;
        width:100%;
        position: absolute;
        bottom:0;
        .item{
            position:absolute;
            bottom:0;
            text-indent: -50%;
            color: var(--gray-60, #787878);
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.2px;
            text-align: center;
            &:first-child{
                text-align: left;
                text-indent: 0;
                left:1px !important;
            }
            &:last-child{
                right:1px !important;
                left: unset !important;
                text-indent: 0;
                text-align: right;
            }
        }
    }//text-wrap
}//range-slider-wrap
</style>
