<template>
  <div class="wrap">
    <div v-show="$store.state.requested" class="loading"></div>
    <div v-show="$store.state.requested" class="spinner-wrap">
      <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
      </svg>
    </div>

    <transition name="component-fade" mode="out-in">
      <div v-if="$route.meta.fullScreen" class="content fullscreen">
        <router-view :key="$route.path"/>
      </div>

    <div v-else class="normal" :class="{ 'isTop-0' : isMenu  }" :key="$route.path">
      <!-- 업데이트 알림 팝업 -->
      <div v-show="versionNotice" @click="reload" class="version-notice">
        <transition name="slide-down">
          <div class="slide-content">
            🚀 업데이트가 있습니다. 클릭 시 새로고침 됩니다. 🚀
          </div>
        </transition>
      </div>
      <!-- //업데이트 알림 팝업 -->

      <!-- 햄버거 버튼 -->
      <!-- <button v-if="!isFolded" style="position:absolute" type="button" class="toggle-nav" @click="toggleNavigation">
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="4" y="10" width="24" height="2" fill="white"/>
          <rect x="4" y="20" width="24" height="2" fill="white"/>
        </svg>
      </button> -->
      <!-- //햄버거 버튼 -->

      <!-- 햄버거 버튼 -->
      <button type="button" class="btn-menu-toggle" @click="toggleNavigation">
        <!-- <ion-icon name="menu-outline" class="icon"></ion-icon> -->
        <!-- 흰색 -->
        <svg class="icon-white" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
          <mask id="mask0_30_3440" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="28" height="28">
            <rect width="28" height="28" fill="white"/>
          </mask>
          <g mask="url(#mask0_30_3440)">
            <path d="M4 21V18.6667H25V21H4ZM4 15.1667V12.8333H25V15.1667H4ZM4 9.33333V7H25V9.33333H4Z" fill="#E3E3E3"/>
          </g>
        </svg>
        <!-- 검정색 -->
        <svg class="icon-block" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
          <mask id="mask0_30_3284" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="28" height="28">
            <rect width="28" height="28" fill="white"/>
          </mask>
          <g mask="url(#mask0_30_3284)">
            <path d="M4 21V18.6667H25V21H4ZM4 15.1667V12.8333H25V15.1667H4ZM4 9.33333V7H25V9.33333H4Z" fill="#4B4B4B"/>
          </g>
        </svg>
      </button>
      <!-- //햄버거 버튼 -->

      <navigation v-if="!isFolded" :style="{ 'z-index':scrollY===0 ? '105' : '' }" />

      <!-- GNB -->
      <div class="gnb-wrap">
        <h1 class="logo">
          <router-link class="link" to="/contract/status-inquiry">
            <svg width="136" height="24" viewBox="0 0 141 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="Isolation_Mode">
                <path id="Vector" d="M31.0522 2.02344H48.125V6.64111H36.2607V11.0053H47.4465V15.8765H36.2607V24.5853H31.0522V2.02344Z" fill="#353535"/>
                <path id="Vector_2" d="M50.8118 2.02344H67.8846V6.64111H56.0203V11.0053H67.2021V15.8765H56.0203V24.5853H50.8118V2.02344Z" fill="#353535"/>
                <path id="Vector_3" d="M73.5951 0.898438C75.2659 0.898438 76.568 2.13866 76.568 3.60118C76.568 5.17681 75.2659 6.41703 73.5951 6.41703C71.9243 6.41703 70.5673 5.17681 70.5673 3.60118C70.5673 2.13866 71.8694 0.898438 73.5951 0.898438ZM71.1046 8.21886H76.0033V24.5874H71.1046V8.21886Z" fill="#353535"/>
                <path id="Vector_4" d="M108.227 2.02344H125.582V6.64111H113.436V10.744H124.904V15.6152H113.436V19.9715H125.923V24.5892H108.227V2.02344Z" fill="#353535"/>
                <path id="Vector_5" d="M100.269 15.3882C99.3122 18.2587 96.3745 20.2204 92.7035 20.2204C88.2872 20.2204 84.7181 17.4163 84.7181 13.3134C84.7181 9.21052 88.2284 6.37907 92.7035 6.37907C96.249 6.37907 99.0298 8.2277 100.112 10.8407L105.309 9.15202C103.454 4.52654 98.516 1.55469 92.676 1.55469C85.2868 1.55469 79.2546 6.32057 79.2546 13.3134C79.2546 20.3062 85.3143 25.0448 92.676 25.0448C98.8062 25.0448 103.905 21.7804 105.544 16.8156L100.273 15.3882H100.269Z" fill="#353535"/>
                <path id="Vector_6" d="M1.52661 13.3134C1.52661 6.32057 7.55877 1.55469 14.948 1.55469C22.3372 1.55469 28.3693 6.37907 28.3693 13.3134C28.3693 20.2477 22.4235 25.0448 14.948 25.0448C7.47249 25.0448 1.52661 20.3062 1.52661 13.3134ZM14.9754 20.2204C19.478 20.2204 22.8745 17.272 22.8745 13.3134C22.8745 9.35482 19.5603 6.37907 14.9754 6.37907C10.3905 6.37907 6.99007 9.29632 6.99007 13.3134C6.99007 17.3305 10.5592 20.2204 14.9754 20.2204Z" fill="#353535"/>
                <path id="Vector_7" d="M134.038 12.2426C136.703 12.2426 138.862 10.0949 138.862 7.44552C138.862 4.79616 136.703 2.64844 134.038 2.64844C131.374 2.64844 129.214 4.79616 129.214 7.44552C129.214 10.0949 131.374 12.2426 134.038 12.2426Z" stroke="#353535" stroke-width="1.22369" stroke-miterlimit="10"/>
                <path id="Vector_8" d="M131.873 4.80859H134.599C135.862 4.80859 136.564 5.3741 136.564 6.16581C136.564 6.68062 136.227 7.19153 135.536 7.35923V7.37093C136.239 7.49183 136.799 7.83504 136.799 8.61895C136.799 9.82017 135.509 10.0698 134.572 10.0698H131.873V4.80469V4.80859ZM134.191 6.89512C134.666 6.89512 135.234 6.86002 135.234 6.40371C135.234 5.94741 134.662 5.91621 134.207 5.91621H133.144V6.89512H134.191ZM134.309 8.96606C134.779 8.96606 135.438 8.93096 135.438 8.44735C135.438 7.96374 134.791 7.92864 134.336 7.92864H133.144V8.96996H134.309V8.96606Z" fill="#353535"/>
              </g>
            </svg>
          </router-link>
        </h1>

        <div class="r-menu-list">

          <div class="menu-item">
            <button class="link bold" @click="clickFileDownload('/pdf/마이오피스B_이용_가이드.pdf','[마이오피스B]_이용_가이드')">
              마이오피스B 이용가이드
            </button>
          </div>

          <div class="menu-item">
            <router-link class="link" to="/member/profile">
              내 정보
            </router-link>
          </div>

          <div class="menu-item">
            <router-link class="link" to="/logout"
              >로그아웃
            </router-link>
          </div>

        </div>

      </div>
      <!-- //GNB -->

      <div class="content-wrap" :class="{'isLnb' : !isFolded}">

        <div class="page-wrap">
          <header class="header" ref="header">
            <!-- <button v-if="isFolded" type="button" class="toggle-nav" @click="toggleNavigation">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="4" y="10" width="24" height="2" fill="#1E1E1E"/>
                <rect x="4" y="20" width="24" height="2" fill="#1E1E1E"/>
              </svg>
            </button> -->
            <div class="page-path-title">
              <!-- <div ref="menuFullName" class="full-path">{{ menuFullName }}</div> -->
              <div ref="pageTitle" class="title">{{ pageTitle }}</div>
            </div>
            <portal-target class="action-bar" name="action-bar" />
          </header>

          <main class="main" ref="main">
            <router-view @totalPages="totalPages" :key="$route.path + isReload" />
            <pagination style="flex-basis:content; margin-bottom:40px;" :totalPages="count"/>
          </main>
        </div>
      </div><!-- //content-wrap -->

    </div>

    </transition>
    <sp-modals></sp-modals>
    <sp-toasts />

    <ErrorMaintenance v-if="maintenanceDetail" :data="maintenanceDetail"></ErrorMaintenance>
  </div>
</template>
<script>
import aws from 'aws-sdk'
import Navigation from './Navigation'
import Pagination from '@/components/common/Pagination'
import SpModals from '@/components/common/SpModals'
import SpToasts from '@/components/common/SpToasts.vue'
import ErrorMaintenance from '@/components/common/error/ErrorMaintenance.vue'
export default {
  name: 'App',
  data () {
    return {
      fullscreen: true,
      count: 0,
      version: null,
      versionNotice: false,
      versionInterval: null,

      scrollY: 0, // 스크롤 위치를 저장할 변수
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll) // 스크롤 이벤트 추가

    this.setViewportHeight()
    window.addEventListener('resize', this.setViewportHeight)

    if (window.innerWidth > 1000) {
      this.$store.commit('toggleIsFolded')
    }

    // 배포 업데이트 알람이
    if (location.href.indexOf('local') == -1) {
      this.checkDistributionVersion()
    }
  },
  methods: {
    handleScroll () {
      this.scrollY = window.scrollY || window.pageYOffset // 현재 스크롤 위치를 저장
    },
    totalPages (count) {
      this.count = count
    },
    toggleNavigation () {
      this.$store.commit('toggleIsFolded')
    },
    checkDistributionVersion () {
      // 최초 한 번만 실행
      setTimeout(async () => {
        this.version = await this.getDistributionVersion()
      }, 10)

      // 주기적으로 실행
      this.versionInterval = setInterval(async () => {
        const version = await this.getDistributionVersion()

        if (this.version !== version) {
          this.versionNotice = true
          clearInterval(this.versionInterval)
        }
      }, 5 * 60 * 1000) // 5분 (5 * 60 * 1000 밀리초)
    },
    // 현재 배포 버전 획득
    async getDistributionVersion () {
      let version = this.version

      await this.$http.get(
        '/.version',
        { withCredentials: false },
      ).then(res => {
        version = res.data
      }).catch(err => {
        console.error(err)
      })

      return version
    },
    reload () {
      location.reload()
    },
  },
  components: {
    Navigation,
    Pagination,
    SpModals,
    SpToasts,
    ErrorMaintenance,
  },
  computed: {
    isMenu () {
      return !this.isFolded && this.scrollY === 0
    },
    isFolded () {
      return this.$store.state.isFolded
    },
    isReload () {
      // 로그인 하거나 새로고침했을때 reload 가1증가 하기때문에  0이나 1은 변화로 감지 안되게 하기위하여 일괄적으로 0으로 만듬
      return this.$store.state.user.reload <= 1 ? 0 : this.$store.state.user.reload
    },
    // lnb () {
    // const parentMenuNo = this.$store.getters.pathToMenu(this.$route.path)?.parentMenuNo || 0
    // return this.$store.state.tree[parentMenuNo] || {}
    // },
    pageTitle () {
      return this.$route.meta.pageTitle || this.$store.getters.pathToMenu(this.$route.path)?.menuNm
    },
    menuFullName () {
      return this.$store.getters.pathToMenu(this.$route.path)?.menuFullNm
    },
    isProduction () {
      return process.env.VUE_APP_MODE === 'production'
    },
    maintenanceDetail () {
      return this.$store.state.user.maintenanceDetail
    },
  },
  watch: {
    '$route.meta.fullScreen': {
      deep: true,
      immediate: true,
      handler: function (isFullscreen) {
        this.fullscreen = isFullscreen
      },
    },
  },
  created () {
    if (!this.isProduction) {
      document.title = `(${process.env.VUE_APP_MODE_KR ?? 'DEV'}) ${document.title}`
    }
  },
}
</script>
<style lang="scss" scoped>
.wrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: stretch;
  min-height: 100%;

  .isTop-0{
    .gnb-wrap{
      padding-left:304px;
    }
    .content-wrap{
      width: calc(100% - 232px);
    }
    .btn-menu-toggle{
      .icon-white{display:block}
      .icon-block{display:none}
    }

  }

  .btn-menu-toggle{
    .icon-white{display:none}
    .icon-block{display:block}
    position:fixed;
    top:15px;
    left:24px;
    z-index:1000;
    border:none;
    background:none;
    cursor: pointer;
  }

.gnb-wrap{
    position:fixed;
    top:0;
    left:0;
    width:100%;
    display:flex;
    align-items: center;
    z-index:100;
    padding:0 24px 0 72px;
    background-color: #F8F7EF;
    height: 57px;
    border-bottom:1px solid var(--yellow-30);
    box-sizing: border-box;
    .r-menu-list{
      margin-left:auto;
      display:flex;
      flex-direction: row;
      flex-wrap: nowrap;
      .menu-item{
        .link{
          &:hover{
            color: var(--Primary) !important;
          }
          &.bold{
            color: var(--gray-100);
            font-weight: 600;
          }
          align-items: center;
          cursor: pointer;
          color: var(--gray-90);
          font-size: 14px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.28px;
          background:none;
          border:none;
          text-decoration: none;
          display:flex;
          flex-wrap: nowrap;
        }
        & .link::before {
          content: "";
          display: inline-block;
          width: 1px;
          height: 8px;
          background-color: var(--yellow-30);
          margin: 0 20px;
        }
        &:first-child{
          .link::before {
            display:none;
          }
        }
        &:last-child{
          .link::before {
            display:none;
          }
          margin-left:20px;
        }

      }
    }
  }

.content-wrap{
  padding-top:57px;
  width:100%;
  // &.isLnb{
  //   width: calc(100% - 232px);
  //   @media (max-width: 1000px) {
  //     display: none
  //   }
  // }
  // height: calc(100% - 57px);

}

  .page-wrap {
    overflow: hidden;
    height: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    background-color: #F8F7EF;
    .header {
      display: flex;
      // justify-content: space-between;
      box-sizing: border-box;
      align-items: center;
      padding: 56px 72px 64px 72px;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 130px;
      .toggle-nav {
        // transform: translate(-36px, -52px);
        transform: translate(-72px, -100px);
      }
      .toggle-nav + .page-path-title {
        transform: translateX(-40px);
      }
      .page-path-title {
        // flex-basis: 100%;
        margin-right:10px;
        .full-path {
          color: #BDBDBD;
          font-weight: 400;
          font-size: 18px;
          line-height: 26px;
        }
        .title {
          color: var(--gray-100);
          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -1.28px;
        }
      }
    }
    .main {
      padding:0 72px;
      // background-color: #fffffff1;
      // border-bottom: 1px solid #d0d0d0;
    }
  }

  .component-fade-enter-active,
  .component-fade-leave-active {
    transition:
      opacity .3s linear,
      filter  .3s linear,
    ;
  }
  .component-fade-enter,
  .component-fade-leave-to {
    opacity: .95;
    filter: blur(2px);
  }
  $offset: 187;
  $duration: 1.4s;
  .spinner-wrap {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001;
  }
  .spinner {
    animation: rotator $duration linear infinite;
  }

  @keyframes rotator {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(270deg); }
  }
  .path {
    stroke-dasharray: $offset;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation:
      dash $duration ease-in-out infinite,
      colors ($duration*4) ease-in-out infinite;
  }
  @keyframes colors {
    0% { stroke: #4285F4; }
    25% { stroke: #DE3E35; }
    50% { stroke: #F7C223; }
    75% { stroke: #1B9A59; }
    100% { stroke: #4285F4; }
  }
  @keyframes dash {
    0% { stroke-dashoffset: $offset; }
    50% {
      stroke-dashoffset: $offset/4;
      transform:rotate(135deg);
    }
    100% {
      stroke-dashoffset: $offset;
      transform:rotate(450deg);
    }
  }

  //td 안에 버튼이 들어간경우 td 높이가 늘어나느거 방지용 스타일
  /deep/ .customButton{
    position:relative;
    .gap{
      width: 50px; height: 32px;position:absolute;top:50%;right:0;margin-top:-16px;
    }
  }

  // /deep/ {
  //   .multiselect {
  //     display: inline-block;
  //     box-sizing: border-box;
  //     margin: 4px 0;
  //     padding:0  2.5px;
  //     line-height: 24px;
  //     min-width: 60px;
  //     min-height: 24px;
  //     vertical-align: middle;
  //     &--disabled {
  //       background: transparent;
  //       .multiselect__select {
  //         background: transparent;
  //       }
  //       .multiselect__single {
  //         background: #f6f6f6;
  //         color: #000;
  //          height: 24px;
  //         padding: 2px 0 0 5px;
  //       }
  //       .multiselect__tags {

  //         background: #f6f6f6;
  //       }
  //       .multiselect__placeholder {
  //         color: #000;
  //       }
  //     }
  //   }

  //   .multiselect__select {
  //     right: -6px;
  //     top: 2px;
  //     line-height: 24px;
  //     height: 24px;
  //     &:before {
  //       border-color: var(--theme-primary-color) transparent transparent;
  //       top: 7px;
  //       border-style: solid;
  //       border-width: 5px 5px 0;
  //     }
  //   }
  //   .multiselect__tag {
  //     background-color: var(--theme-primary-color);
  //     margin: 2.5px;
  //     vertical-align: top;
  //     height: 20px;
  //   }
  //   .multiselect__tag-icon {
  //     &:hover {
  //       background: var(--theme-primary-color);
  //     }
  //     &:after {
  //       color: #fff;
  //     }
  //   }
  //   .multiselect__tags {
  //     padding: 0;
  //     min-height: 22px;
  //     margin: 0;
  //     border-color: #ddd;
  //     box-sizing: border-box;
  //   }
  //   .multiselect--active {
  //     .multiselect__tags .multiselect__input {
  //       // margin-top: 5px;
  //     }
  //     .multiselect__tags-wrap {
  //       overflow: initial;
  //       height: 22px;
  //     }
  //   }
  //   .multiselect__tags-wrap {
  //     position: relative;
  //     // top: 2px;
  //     overflow: auto;
  //     height: fit-content;
  //     display: inline-block;
  //     width: calc(100% - 24px);
  //   }
  //   .multiselect__input {
  //     width: auto;
  //     font-size: 14px;
  //     line-height: 24px;
  //     text-indent: 0px;
  //     margin-bottom: 0;
  //   }
  //   // .multiselect__placeholder {
  //   //   font-size: 14px;
  //   //   line-height: 24px;
  //   //   padding: 0;
  //   //   margin: 0;
  //   //   text-indent: 5px;
  //   //   height: 24px !important;
  //   //   overflow: hidden;
  //   //   display: block !important;
  //   //   text-overflow: ellipsis;
  //   //   font-weight: normal;
  //   // }
  //   .multiselect__option {
  //     padding: 0 10px;
  //     height: 24px;
  //     min-height: 0;
  //     line-height: 24px;
  //     font-size: 14px;
  //   }
  //   .multiselect__option--highlight::after, .multiselect__option--selected::after {
  //     height: 24px;
  //     min-height: 0;
  //     line-height: 24px;
  //   }
  //   .multiselect__content-wrapper{
  //     min-width: 150px;
  //     width: calc(100% - 5px);
  //     padding: 0 2.5px;
  //     box-sizing: border-box;
  //     ul {
  //       padding-inline-start: 0;
  //       li {
  //         width: 100%;
  //         overflow: hidden;
  //       }
  //     }
  //   }
  //   .multiselect__option--highlight {
  //     background-color: var(--theme-primary-color);
  //     &::after {
  //       background-color: var(--theme-primary-color);
  //     }
  //   }
  //   .multiselect__single {
  //     font-weight: normal;
  //     font-size: 14px;
  //     margin: 1px 0;
  //     height: 22px;
  //     line-height: 22px;
  //     overflow: hidden;
  //     white-space:nowrap;
  //     text-overflow: ellipsis;
  //   }
  // }
  /deep/ .ck-editor {
    .ck.ck-labeled-field-view>.ck.ck-labeled-field-view__input-wrapper>.ck.ck-label {
      height: 14px;
    }
  }
  .version-notice{
    z-index: 10;
    position: fixed;
    background-color: #2cb6af;
    color: white;
    font-weight: bold;
    width: 100%;
    text-align: center;
    padding: 10px;
    animation: showfadeout 5s normal;
    opacity: 1;
    cursor: pointer;
  }
  @keyframes showfadeout {
    50% {
      transition: transform 3s;
      transform: translateY(100%);
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
}

@media screen and (max-width: 600px) {

  .container {
    padding: 0 7.5px;
  }

  .wrap {
    .normal {
      // display: block;
      display: flex;
      min-height: 100%;
      align-content: initial;
      width: 100%;
    }
    .navigation + .page-wrap {
      display: none;
    }
    .page-wrap {
      overflow: initial;
      .header {
        // position: sticky;
        background: #F8F7EF;
        // top:0;
        z-index: 95;
        // z-index: 900;
        flex-basis: initial;
        padding: 30px 7.5px 6px 7.5px;
        .toggle-nav {
          margin : 0;
          transform: translate(0,0);
        }
        .toggle-nav + .page-path-title {
          transform: translateX(0);
        }
        .page-path-title {
          // flex-basis: 100%;
          margin-right:10px;
          overflow: hidden;
          .full-path {
            padding: 4px 5px;
            color: #BDBDBD;
            font-weight: 500;
            font-size: 14px;
            line-height: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .title {
            padding: 0 5px;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
          }
        }
        .action-bar {
          text-align: right;
        }
      }
      .main {
        padding:50px 20px 0 20px;
        background-color: #F8F7EF;
        // border-bottom: 1px solid #d0d0d0;
        /deep/ .filter label {
          display: block;
        }
        /deep/ .filter .inputbox>label {
          display: inline-block;
        }
      }
    }
  }

}

</style>
<style>
/* @media screen and (max-width: 1920px){
  .zoom-box {
    transform: scale(0.8);
    transform-origin:0 0;
    width:125%;
    height: 125% !important;
  }
  header.header{
    padding-bottom: 30px !important;
  }
} */
</style>
