const getDefaultState = () => {
  return {

    list: [],
    statusCards: {
      requestCount: 0,
      inProgressCount: 0,
      endProgressCount: 0,
    },
    count: 0,
    originFilter: {},
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    totalPages: state => {
      const limit = state.originFilter?.limit || 14
      const count = state.count || 0
      return Math.ceil(count / limit)
    },
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setResponse (state, data) {
      state.list = data.workOrderRequests
      state.statusCards = data.statusCards
      state.count = data.totalCount
    },
    setOriginFilter (state, filter) {
      state.originFilter = JSON.parse(JSON.stringify(filter))
    },

    // setWorkOrderCategorys (state, data) {
    //   state.category = data.category
    // },
    // setWorkOrderSubCategorys (state, data) {
    //   state.sub_category = data.category
    // },
  },
  actions: {
    // 요청사항 리스트
    async getList ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
              `${this._vm.SPARKONE_OFFICE_API_URL}office/work-orders`,
              payload,
        )
        commit('setResponse', response.data)
        commit('setOriginFilter', payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    // 요청사항 상세데이터 호출하기
    async getWorkOrderDetail ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
              `${this._vm.SPARKONE_OFFICE_API_URL}office/work-order/${payload.workOrderRequestNo}?membershipId=${payload.membershipId}&managerType=${payload.managerType}&tenantId=${payload.tenantId}&managerId=${payload.managerId}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 요청사항 상세데이터 수정하기
    async putWorkOrderDetail ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.put(
              `${this._vm.SPARKONE_OFFICE_API_URL}office/work-order/${payload.workOrderRequestNo}?membershipId=${payload.membershipId}&managerType=${payload.managerType}&tenantId=${payload.tenantId}&managerId=${payload.managerId}`,
              payload.data,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 요청 취소하기
    async deleteWorkOrderDetail ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.delete(
              `${this._vm.SPARKONE_OFFICE_API_URL}office/work-order/${payload.workOrderRequestNo}?membershipId=${payload.membershipId}&managerType=${payload.managerType}&tenantId=${payload.tenantId}&managerId=${payload.managerId}`,
              payload.data,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    /* 상품 구분 */
    async getWorkOrderCategorys ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
              `${this._vm.SPARKONE_OFFICE_API_URL}office/work-order-categorys?membershipId=${payload.membershipId}&managerType=${payload.managerType}&tenantId=${payload.tenantId}&managerId=${payload.managerId}&checkContractStatusYn=${payload.checkContractStatusYn}`,
        )
        // commit('setWorkOrderCategorys', response.data)

      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    /* 상품 구분 */
    async getWorkOrderSubCategorys ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
                `${this._vm.SPARKONE_OFFICE_API_URL}office/work-order-category/${payload.categoryId}/sub-categorys?membershipId=${payload.membershipId}&managerType=${payload.managerType}&tenantId=${payload.tenantId}&managerId=${payload.managerId}`,
        )
        // commit('setWorkOrderSubCategorys', response.data)

      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    /* 요청사항 등록 */
    async postWorkOrderRegistration ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.post(
                  `${this._vm.SPARKONE_OFFICE_API_URL}office/work-order?membershipId=${payload.membershipId}&managerType=${payload.managerType}&tenantId=${payload.tenantId}&managerId=${payload.managerId}`,
                  payload.data,
        )
        // commit('setWorkOrderSubCategorys', response.data)

      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

  },
}
