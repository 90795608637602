export default {
  namespaced: true,
  state: {
    list: [],
    branch_list: [],
  },
  getters: {

  },
  mutations: {
    setResponse (state, data) {
      state.list = data.codeList
    },
    setBranchResponse (state, data) {
      state.branch_list = data.branchList
    },
  },
  actions: {
    async getCommonCode ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARKONE_OFFICE_API_URL}common/code`,
          payload,
        )
        commit('setResponse', response.data)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getBranchList ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARKONE_OFFICE_API_URL}common/branch`,
          payload,
        )
        commit('setBranchResponse', response.data)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getGroupTypeList (context, payload) {
      let response = null

      try {
        response = await this._vm.get(
                `${this._vm.SPARKONE_OFFICE_API_URL}common/common-code`,
                payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }

      return response
    },
    async getAdminDepartmentFullName (context, payload) {
      let response = null

      try {
        response = await this._vm.get(
              `${this._vm.SPARKONE_OFFICE_API_URL}common/admin-department-full-name`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }

      return response
    },
  },
}
