import router from '@/router'
import axios from 'axios'
import { decode } from 'js-base64'
const JWT_EXPIRE_TIME = 1000 * 3600

export default {
  namespaced: true,
  state: {
    tree: {},
    reload: 0, // 왼쪽 메뉴 상단 셀박에서 어카운트 변경시에 1씩 증가하는 변수(값이 변하면 <router-view :key="reload"> 감지되어 새로고침안하고 리로드 할수있음)
    pathToMenu: {},
    accessToken: null,
    selectableMembershipList: [],
    selectableMembershipListByKey: {},
    selectedAccountId: 0,
    selectedManagerType: null,
    selectedTenantId: 0,
    selectedMenuIndex: 0,

    maintenanceDetail: null, // 비어있으면 정상 상태고  데이터가 있으면 점검중 상태
  },
  getters: {
    userName: state => {
      if (state.accessToken) {
        return JSON.parse(
          decode(state.accessToken.split('.')[1]),
        ).userName
      }
      return ''
    },
    userLoginId: state => {
      if (state.accessToken) {
        return JSON.parse(
          decode(state.accessToken.split('.')[1]),
        ).userLoginId
      }
      return ''
    },
    userId: state => {
      if (state.accessToken) {
        return JSON.parse(
          decode(state.accessToken.split('.')[1]),
        ).userId
      }
      return ''
    },
  },
  mutations: {
    setAccessToken (state, token) {
      state.accessToken = token
    },
    setSelectableMembershipList (state, list) {
      state.selectableMembershipList = list
    },
    setSelectableMembershipListByKey (state, data) {
      state.selectableMembershipListByKey = data
    },
    setSelectedAccountId (state, id) {
      state.selectedAccountId = id
    },
    setSelectedManagerType (state, type) {
      state.selectedManagerType = type
    },
    setSelectedTenantId (state, id) {
      state.selectedTenantId = id
    },
    setSelectedMenuIndex (state, idx) {
      state.selectedMenuIndex = idx
    },
    setReload (state, idx) {
      state.reload++
    },
  },
  actions: {
    async login ({ dispatch }, payload) {
      let response = null
      try {
        response = await this._vm.post(
          `${this._vm.SPARKONE_OFFICE_API_URL}office/login`,
          payload,
        )
        await dispatch('onLoginSuccess', response)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    // refresh-token 삭제 (로그아웃)
    async logout ({ getters, state, dispatch }) {

      try {
        const payload = {
          accountNo: getters.accountNo,
        }
        await this._vm.post(
          `${this._vm.SPARKONE_OFFICE_API_URL}office/logout`,
          payload,
        )
        state.reload = 0
        await dispatch('processSetDefault')

        window.localStorage.clear()
        console.log('logout함수 실행! > localStorage clear')

        // 로그아웃 화면 결정용
        return 'ok'
      } catch (error) {
        console.log(error)
        return 'error'
      }
    },
    // 로그인 성공 처리 (토큰 저장 / 자동 갱신 등록)
    async onLoginSuccess ({ commit, dispatch }, payload) {
      try {
        commit('setAccessToken', payload.data.accessToken)
        // Silent Refresh
        setTimeout(async () => {
          if (await dispatch('refresh')) {
          } else {
            router.push('/login')
          }
        }, JWT_EXPIRE_TIME)
      } catch (error) {
        return false
      }
      return true
    },
    // 토큰 갱신
    async refresh ({ state, dispatch }) {
      try {

        if (router.currentRoute.path === '/login') {
          return false
        }
        const response = await this._vm.post(
          `${this._vm.SPARKONE_OFFICE_API_URL}office/refresh`,
        )
        if (response.response?.data?.error?.code == 403) {
          throw new Error('토큰 갱신 실패')
        }

        await dispatch('onLoginSuccess', response)
        return true
      } catch (error) {
        await dispatch('processSetDefault')
        // alert('로그인이 필요합니다. 로그인해주세요.')
        console.log('로그인이 필요합니다. 로그인해주세요.')
        state.reload = 0
        return false
      }
    },
    async getSelectableAccountList ({ commit, dispatch }) {
      try {
        const response = await this._vm.get(
          `${this._vm.SPARKONE_OFFICE_API_URL}office/manager/accounts`,
        )

        // 사용가능한 어카운트가 없을경우 (비어있음 상태의 삭제계약만 있는 경우)
        if (response.data.selectableMembershipList.length == 0) {
          alert('어드민에 접근할 수 없는 계정입니다. \n관리자에게 문의하세요.')
          await dispatch('logout')
          return false
        }

        commit('setSelectableMembershipList', response.data.selectableMembershipList)
        commit('setSelectableMembershipListByKey', response.data.selectableMembershipListByKey)

        return true

      } catch (error) {
        return false
      }
    },
    async getMenu ({ state, commit, dispatch }, payload) {

      if (!payload.managerId) {
        // alert('managerId가 없습니다.\n다시 로그인해주세요')
        // await dispatch('logout')
        // location.href = '/login'
        return
      }

      try {
        const response = await this._vm.get(
            `${this._vm.SPARKONE_OFFICE_API_URL}office/system/menu/${payload.managerId}`,
            payload,
        )
        if (response.response?.data?.error?.code == 403) {
          throw new Error('권한 없음')
        }
        commit('setTree', response.data.tree, { root: true })
        commit('setPathToMenu', response.data.pathToMenu, { root: true })
        window.localStorage.setItem('selected_manager_id', payload.managerId)

        // console.log('payload.needReload', payload.needReload)
        // 메뉴 뿌려줄 때 초기화면 이동
        if (payload.needReload) { // 화면 마운트가 필요한 경우
          // console.log(router.currentRoute.name, state.reload)
          if (router.currentRoute.name !== 'ContractStatusInquiry' && (state.reload === 0)) {
            // 로그인 한경우에는 해당 조건까지 들어오게되는데 로그인 시점에는 reload 가 0 이므로 성립
            // 로그인 이후부터는 reload 값이 증가해서 조건성립이 안되서 해당 로직은 로그인했을때 한번만 실행된다고 생각하면된다.
            commit('setReload')// reload 증가시켜서 0이 아니게 해주기
            router.push({ name: 'ContractStatusInquiry' })
          }
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.log(error)
          await dispatch('processSetDefault')
          // alert('접근 권한이 확인되지 않습니다. 관리자에게 문의해주세요.')
          console.log('접근 권한이 확인되지 않습니다. 관리자에게 문의해주세요.')
          if (router.currentRoute.name !== 'Login') {
            router.push({ name: 'Logout' }).catch(() => {})
          }
        }
      }
    },
    // 권한이 없어졌을 경우 등 정상 로그아웃은 아니나 로그아웃과 동일한 초기화 처리
    async processSetDefault ({ getters, commit }) {
      commit('setAccessToken', null)
      commit('setTree', {}, { root: true })
      commit('setPathToMenu', {}, { root: true })

    },
    async getVerificationToken ({ commit, dispatch }, payload) {
      if (!payload.token) {
        return false
      }
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARKONE_OFFICE_API_URL}office/verification/token/${payload.token}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
  },
}
