export default {
  namespaced: true,
  state: {
    list: [],
    count: 0,
    originFilter: {},
    noticeTypes: [],
    noticeCategories: [],
  },
  getters: {
    totalPages: state => {
      const limit = state.originFilter?.limit || 14
      const count = state.count || 0
      return Math.ceil(count / limit)
    },
    noticeTypes: state => {
      return state.noticeTypes
    },
    noticeCategories: state => {
      return state.noticeCategories
    },
  },
  mutations: {
    setNoticeTypes (state, data) {
      state.noticeTypes = data.noticeTypes
    },
    setNoticeCategories (state, data) {
      state.noticeCategories = data.noticeCategories
    },
    setResponse (state, data) {
      state.list = data.notices
      state.count = data.totalCount
    },
    setOriginFilter (state, filter) {
      state.originFilter = JSON.parse(JSON.stringify(filter))
    },
    resetState (state) {
      const defaultState = {
        list: [],
        count: 0,
        originFilter: {},
        noticeTypes: [],
        noticeCategories: [],
      }
      Object.assign(state, defaultState)
    },
    // setWorkOrderCategorys (state, data) {
    //   state.category = data.category
    // },
    // setWorkOrderSubCategorys (state, data) {
    //   state.sub_category = data.category
    // },
  },
  actions: {
    async getList ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
              `${this._vm.SPARKONE_OFFICE_API_URL}office/notices`,
              payload,
        )
        commit('setResponse', response.data)
        commit('setOriginFilter', payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getNoticeTypes ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.SPARKONE_OFFICE_API_URL}office/notice-types`)
        commit('setNoticeTypes', response.data)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getNoticeCategories ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.SPARKONE_OFFICE_API_URL}office/notice-goods-ids`, payload)
        commit('setNoticeCategories', response.data)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getNoticeDetail ({ commit }, payload) {
      let response = null
      const noticeId = payload.noticeId
      delete payload.noticeId
      try {
        response = await this._vm.get(
          `${this._vm.SPARKONE_OFFICE_API_URL}office/notice/${noticeId}`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
  },
}
