const getDefaultState = () => {
  return {
    list: [],
    count: 0,
    originFilter: {},
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    totalPages: state => {
      const limit = state.originFilter?.limit || 14
      const count = state.count || 0
      return Math.ceil(count / limit)
    },
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setResponse (state, data) {
      state.list = data.members
      state.count = data.totalCount
    },
    setOriginFilter (state, filter) {
      state.originFilter = JSON.parse(JSON.stringify(filter))
    },
  },
  actions: {
    // async getList ({ commit }, payload) {
    //   let response = null
    //   try {
    //     response = await this._vm.get(
    //         `${this._vm.SPARKONE_OFFICE_API_URL}account/office/member`,
    //         payload,
    //     )
    //     commit('setResponse', response.data)
    //     commit('setOriginFilter', payload)
    //   } catch (error) {
    //     if (error.response) {
    //       response = error
    //     } else {
    //       throw error
    //     }
    //   }
    //   return response
    // },
    async getProfile ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
            `${this._vm.SPARKONE_OFFICE_API_URL}office/mypage/profile`,
            payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    // 폰으로 인증번호 받기
    async putPhoneNumberConfirm ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.put(
            `${this._vm.SPARKONE_OFFICE_API_URL}office/mypage/mobile-phone-number/2fa`,
            payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    // 인증번호와 바뀌번호 보내기
    async putPhoneNumberChange ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.put(
            `${this._vm.SPARKONE_OFFICE_API_URL}office/mypage/mobile-phone-number/${payload.key}`,
            payload.data,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 기존 패스워드 확인하기
    async putPasswordCheck ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.put(
            `${this._vm.SPARKONE_OFFICE_API_URL}office/mypage/password-check`,
            payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 패스워드 변경
    async putPasswordChange ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.put(
            `${this._vm.SPARKONE_OFFICE_API_URL}office/mypage/password`,
            payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 패스워드 변경
    async deleteMember ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.delete(
            `${this._vm.SPARKONE_OFFICE_API_URL}office/mypage/user`,
            payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

  },
}
