const getDefaultState = () => {
  return {
    list: [],
    count: 0,
    originFilter: {},
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    totalPages: state => {
      const limit = state.originFilter?.limit || 14
      const count = state.count || 0
      return Math.ceil(count / limit)
    },
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setResponse (state, data) {
      state.list = data.managerInfoList
      state.count = data.totalCount
    },
    setOriginFilter (state, filter) {
      state.originFilter = JSON.parse(JSON.stringify(filter))
    },
  },
  actions: {
    // 관리자 정보 리스트
    async getList ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
            `${this._vm.SPARKONE_OFFICE_API_URL}office/managers`,
            payload,
        )
        commit('setResponse', response.data)
        commit('setOriginFilter', payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 관리자 등록
    async postManager ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.post(
            `${this._vm.SPARKONE_OFFICE_API_URL}office/manager?membershipId=${payload.membershipId}&managerType=${payload.managerType}&tenantId=${payload.tenantId}&managerId=${payload.managerId}`,
            payload.data,
        )

      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 관리자 상세
    async getManagerDetail ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
            `${this._vm.SPARKONE_OFFICE_API_URL}office/manager/${payload.manager_id}/detail`,
            payload.data,
        )

      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 관리자 수정
    async putManagerDetail ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.put(
            `${this._vm.SPARKONE_OFFICE_API_URL}office/manager/${payload.manager_id}?membershipId=${payload.membershipId}&managerType=${payload.managerType}&tenantId=${payload.tenantId}&managerId=${payload.managerId}`,
            payload.data,
        )

      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // async getInfo ({ commit }, payload) {
    //   let response = null
    //   try {
    //     response = await this._vm.get(
    //         `${this._vm.SPARKONE_OFFICE_API_URL}account/office/member/${payload.memberId}`,
    //         payload,
    //     )
    //   } catch (error) {
    //     if (error.response) {
    //       response = error
    //     } else {
    //       throw error
    //     }
    //   }
    //   return response
    // },
    // async putInfo ({ commit }, payload) {
    //   let response = null
    //   try {
    //     response = await this._vm.put(
    //         `${this._vm.SPARKONE_OFFICE_API_URL}account/office/member/${payload.memberId}`,
    //         payload,
    //     )
    //   } catch (error) {
    //     if (error.response) {
    //       response = error
    //     } else {
    //       throw error
    //     }
    //   }
    //   return response
    // },
    // // #####

    // async postInfo ({ commit }, payload) {
    //   let response = null
    //   try {
    //     response = await this._vm.post(
    //         `${this._vm.SPARKONE_OFFICE_API_URL}account/member`,
    //         payload,
    //     )
    //   } catch (error) {
    //     if (error.response) {
    //       response = error
    //     } else {
    //       throw error
    //     }
    //   }
    //   return response
    // },

    // async putStatusInfo ({ commit }, payload) {
    //   let response = null
    //   try {
    //     response = await this._vm.put(
    //         `${this._vm.SPARKONE_OFFICE_API_URL}account/office/member/member-status/${payload.memberId}`,
    //         payload,
    //     )
    //   } catch (error) {
    //     if (error.response) {
    //       response = error
    //     } else {
    //       throw error
    //     }
    //   }
    //   return response
    // },
    // async cancelReservationMember ({ commit }, payload) {
    //   let response = null

    //   try {
    //     response = await this._vm.put(
    //         `${this._vm.SPARKONE_OFFICE_API_URL}account/office/member/reservation/cancel/${payload.memberId}`,
    //         payload,
    //     )
    //   } catch (error) {
    //     if (error.response) {
    //       response = error
    //     } else {
    //       throw error
    //     }
    //   }
    //   return response
    // },
    // async getTenantContractUseCnt ({ commit }, payload) {
    //   let response = null
    //   try {
    //     response = await this._vm.get(
    //         `${this._vm.SPARKONE_OFFICE_API_URL}account/office/member/getTenantContractUseCnt`,
    //         payload,
    //     )
    //   } catch (error) {
    //     if (error.response) {
    //       response = error
    //     } else {
    //       throw error
    //     }
    //   }
    //   return response
    // },
  },
}
