const getDefaultState = () => {
  return {
    list: [],
    count: 0,
    originFilter: {},
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    totalPages: state => {
      const limit = state.originFilter?.limit || 20
      const count = state.count || 0
      return Math.ceil(count / limit)
    },
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setResponse (state, data) {
      state.list = data.iotAutoControls
      state.count = data.totalCount
    },
    setOriginFilter (state, filter) {
      state.originFilter = JSON.parse(JSON.stringify(filter))
    },
  },
  actions: {
    // 자동화 리스트 조회
    async autoList ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
            `${this._vm.SPARKONE_OFFICE_API_URL}office/iot/auto-controls`,
            payload,
        )
        response.data.iotAutoControls.forEach(item => {
          item.isToggle = false
        })

        commit('setResponse', response.data)
        commit('setOriginFilter', payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 자동화 상세데이터
    async getAutoDetail ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
            `${this._vm.SPARKONE_OFFICE_API_URL}office/iot/auto-control/${payload.iotAutoControlNo}`,
            payload.data,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // ##### IOT 자동화 변경
    async putIotAutoControl ({ commit }, payload) {
      let response = null

      try {
        response = await this._vm.put(
          `${this._vm.SPARKONE_OFFICE_API_URL}office/iot/auto-controls/${payload.iotAutoControlNo}/active?membershipId=${payload.membershipId}&managerType=${payload.managerType}&tenantId=${payload.tenantId}&managerId=${payload.managerId}`,
          payload.data,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // #####자동화 예외 날짜 목록 조회
    async getHolidayList ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
            `${this._vm.SPARKONE_OFFICE_API_URL}office/iot/auto-controls/holidays`,
            payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // #####자동화 예외 날짜 등록
    async postHoliday ({ commit }, payload) {
      let response = null

      // response = 'ok'
      try {
        response = await this._vm.post(
            `${this._vm.SPARKONE_OFFICE_API_URL}office/iot/auto-controls/holidays?membershipId=${payload.membershipId}&managerType=${payload.managerType}&tenantId=${payload.tenantId}&managerId=${payload.managerId}`,
            payload.data,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // #####자동화 예외 날짜 삭제
    async deleteHoliday ({ commit }, payload) {
      let response = null

      try {
        response = await this._vm.delete(
            `${this._vm.SPARKONE_OFFICE_API_URL}office/iot/auto-controls/holidays?membershipId=${payload.membershipId}&managerType=${payload.managerType}&tenantId=${payload.tenantId}&managerId=${payload.managerId}`,
            payload.data,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // #####자동화 등록
    async postIotRegistration ({ commit }, payload) {
      let response = null

      try {
        response = await this._vm.post(
            `${this._vm.SPARKONE_OFFICE_API_URL}office/iot/auto-control?membershipId=${payload.membershipId}&managerType=${payload.managerType}&tenantId=${payload.tenantId}&managerId=${payload.managerId}`,
            payload.data,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // #####자동화 수정(변경)
    async putIotRegistration ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.put(
            `${this._vm.SPARKONE_OFFICE_API_URL}office/iot/auto-controls/${payload.auto_control_no}?membershipId=${payload.membershipId}&managerType=${payload.managerType}&tenantId=${payload.tenantId}&managerId=${payload.managerId}`,
            payload.data,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // #####자동화 등록에 필요한 정보 조회
    async getRegister  ({ commit }, payload) {
      let response = null

      // response = {
      //   data: {
      //     rooms: [
      //       {
      //         roomId: 65,
      //         displayName: '아주빌딩 / 2층 / 201호',
      //         devices: [
      //           {
      //             deviceId: 'climate.2_f002_ac_1',
      //             deviceType: 'hvac-system',
      //             deviceTypeName: '냉난방기',
      //             deviceName: '워크존1',
      //           },
      //           {
      //             deviceId: 'climate.2_f002_ac_2',
      //             deviceType: 'hvac-system',
      //             deviceTypeName: '냉난방기',
      //             deviceName: '워크존 2',
      //           },
      //         ],
      //       },
      //       {
      //         roomId: 66,
      //         displayName: '아주빌딩w / 3층 / 203호',
      //         devices: [
      //           {
      //             deviceId: 'climate.2_f002_ac_1',
      //             deviceType: 'hvac-system',
      //             deviceTypeName: '블라인드',
      //             deviceName: '워크존1',
      //           },
      //           {
      //             deviceId: 'climate.2_f002_ac_2',
      //             deviceType: 'hvac-system',
      //             deviceTypeName: '블라인드',
      //             deviceName: '워크존 2',
      //           },
      //         ],
      //       },
      //       {
      //         roomId: 67,
      //         displayName: '아주빌딩w / 3층 / 203호',
      //         devices: [
      //           {
      //             deviceId: 'climate.2_f002_ac_1',
      //             deviceType: 'hvac-system',
      //             deviceTypeName: '실내조명',
      //             deviceName: '워크존1',
      //           },
      //           {
      //             deviceId: 'climate.2_f002_ac_2',
      //             deviceType: 'hvac-system',
      //             deviceTypeName: '실내조명',
      //             deviceName: '워크존 2',
      //           },
      //         ],
      //       },
      //     ],
      //   },
      // }

      try {
        response = await this._vm.get(
            `${this._vm.SPARKONE_OFFICE_API_URL}office/iot/auto-controls/register`,
            payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

  },
}
