<template>

  <ul class="picture-list-wrap">
    <li v-for="(image, index) in images" :key="index" class="image-preview"
    :style="{
      backgroundImage: `url(${image.preview || image.url})`,
    }"
    >
      <span class="overlay" v-if="edit">
        <button class="btn-delet" type="button" @click="fileDelet(index)">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="Union" fill-rule="evenodd" clip-rule="evenodd" d="M14.7071 2.70726L15.4142 2.00015L14 0.585938L13.2929 1.29304L8.00003 6.58594L2.70714 1.29304L2.00003 0.585938L0.585815 2.00015L1.29292 2.70726L6.58582 8.00015L1.29292 13.293L0.585815 14.0002L2.00003 15.4144L2.70714 14.7073L8.00003 9.41437L13.2929 14.7073L14 15.4144L15.4142 14.0002L14.7071 13.293L9.41424 8.00015L14.7071 2.70726Z" fill="white"/>
          </svg>
        </button>
      </span>
      <!-- <img :src="image.preview || image.url" alt="img" /> -->
    </li>
    <li v-if="edit" class="btn-fileUpload" :class="{ 'disabled':disabled }">
      <input
        type="file"
        :disabled="disabled"
        :filename="data.fileName"
        @change="fileUpload"
        ref="attached_files"
        :accept="accept"
        multiple
      />
      <div class="icon_plus">
        <span class="line_y"></span>
        <span class="line_x"></span>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'SpPicture',
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
    accept: {
      type: String,
      default: '',
    },
    maxSize: {
      type: Number,
      default: 10 * 1024 * 1024, // 10MB in bytes
    },
  },
  data () {
    return {
      images: this.value, // 미리보기 이미지 URL과 알파벳을 저장하는 배열
      data: {
        fileData: null, // 업로드 데이터 변수
        fileName: '', // 파일이름
      },
    }
  },
  watch: {
    value (newValue) {
      if (!newValue) {
        return
      }
      this.images = newValue
    },

    images (newValue) {
      if (!newValue) {
        return
      }
      this.$emit('input', this.images)
    },
  },
  methods: {
    // 파일 업로드
    async fileUpload () {
      const files = this.$refs.attached_files.files

      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        // console.log(file)
        if (file && file.size > this.maxSize) {
          this.$refs.attached_files.value = ''
          this.alert({
            title: `업로드 가능한 사진 용량은 최대 ${this.formattedMb(this.maxSize)} 입니다.`,
            message: '',
          })
          return
        }

        this.data.fileData = file
        this.data.fileName = this.data.fileData.name
        this.$store.commit('increaseRequestCount')
        this.s3Upload2(file, false, 'workorder_registration', null, this.maxSize, 0, false)
          .then(response => {
            if (this.images.filter(i => i.fileName === response.fileName).length > 0) {
              this.alert({
                title: '동일한 이미지가 존재합니다.',
                message: '',
              })
              return
            }

            const imageUrl = URL.createObjectURL(file)
            response.preview = imageUrl
            this.images.push(response)
          })
          .catch(error => alert(error))
          .finally(() => {
            this.$store.commit('decreaseRequestCount')
          })
      }
      this.$refs.attached_files.value = ''
    },

    // 파일 삭제
    fileDelet (index) {
      this.images.splice(index, 1)
    },

    formattedMb (data) {
      const bytes = data
      const megabytes = bytes / (1024 * 1024) // 1MB = 1024 * 1024 bytes
      return megabytes.toFixed() + 'MB'
    },

  },
  beforeDestroy () {
    this.data.fileData = null
    this.data.fileName = ''
    // this.$refs.attached_files.value = ''
  },
}
</script>

<style lang="scss" scoped>
.picture-list-wrap{
  padding:0;
  margin:0;
  display:flex;
  flex-direction: row;
  list-style: none;
  gap: 10px;
  li{
    min-width:80px;
    min-height:80px;
  }
  .image-preview {
    width:80px;
    height:80px;
    background-size:cover;
    background-position:center;
    position: relative;
    border-radius: 40px;
    overflow: hidden;
    .overlay {
      will-change:opacity,visibility;
      visibility: hidden;
      opacity: 0;
      // transition: opacity 0.2s ease, visibility 0.2s ease;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 24px;
      background-color: rgba(0, 0, 0, 0.5);
      .btn-delet{
        position:absolute;
        left:50%;
        top:50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
        background: none;
        border: 0;
        z-index: 10;
      }
    }
    &:hover .overlay {
      visibility: visible;
      opacity: 1;
    }
    img{
      width:100%;
      height:100%;
      object-fit:cover
    }
  }
  .btn-fileUpload{
    position:relative;
    width:80px;
    height:80px;
    border-radius: 200px;
    background: var(--gray-100);
    display:flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .icon_plus{
      position:relative;
      width:18px;
      height:18px;
      .line_y,
      .line_x {
        position: absolute;
        display: block;
        background: #fff;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      .line_y{width:2px;height:18px;}
      .line_x{width:18px;height:2px;}
    }

    &.disabled{
      cursor:none;
      background: var(--yellow-20);
      .icon_plus{
        .line_y,.line_x{background:#A4A4A4;}
      }
    }
    input{
      cursor: pointer;
      position: absolute;
      top: -30px;
      left: -30px;
      width: calc(100% + 30px);
      height: calc(100% + 30px);
      opacity: 0;
      z-index: 3;
    }
  }
}
</style>
