<template>
  <div>
    <popup
      v-for="(modal, i) in modals"
      :class="customClass(modal)"
      :key="i"
      :title="modal.title"
      :description="modal.message"
      :closeOnClickOutside="false"
    >
      <div class="button-group">
        <sp-button
          v-if="modal.actionCode == 'ALERT'"
          class="button big primary"
          @click="onEmit(i, { emit: 'alert', value: undefined })"
          >확인
        </sp-button>
        <sp-button
          v-if="modal.actionCode == 'LOGIN'"
          class="button big primary"
          @click="
            () => {
              modals.splice(i);
              $router.push('/login').catch((e) => {});
            }
          "
          >확인
        </sp-button>
        <sp-button
          v-if="modal.actionCode == 'DISTRIBUTION_BATCH'"
          class="button big primary"
          @click="
            () => {
              modals.splice(i);
              $router.push('/tenant/info').catch((e) => {});
            }
          "
          >확인
        </sp-button>

        <sp-button
          v-if="modal.actionCode == 'CONFIRM'"
          class="button big btn-cancel"
          @click="onEmit(i, { emit: 'confirm', value: false })"
          >취소
        </sp-button>
        <sp-button
          v-if="modal.actionCode == 'CONFIRM'"
          class="button big primary"
          @click="onEmit(i, { emit: 'confirm', value: true })"
          >확인
        </sp-button>
      </div>
    </popup>
  </div>
</template>
<script>
export default {
  name: 'SpModals',
  data () {
    return {}
  },
  mounted () {},
  methods: {
    onEmit (i, emitKV) {
      const reloadModal = this.modals[i]
      this.modals.splice(i)
      this.$root.$emit(emitKV.emit, emitKV.value)
      if (reloadModal.actionCode === 'ALERT' && reloadModal.message === '해당 기능을 이용할 권한이 없습니다.') {
        window.location.reload()
      }
    },
    customClass (modal) {
      switch (modal.actionCode) {
        case 'ALERT':
          return 'alert-box'
        case 'LOGIN':
          return 'login-box'
        case 'DISTRIBUTION_BATCH':
          return 'distribution_batch-box'
        case 'CONFIRM':
          return 'confirm-box'
        default:
          return ''
      }
    },
  },
  computed: {
    modals () {
      return this.$store.state.modals
    },
  },
  watch: {},
}
</script>
<style lang="scss" scoped>
/deep/ .popup-wrap {
  &.confirm-box {
    .button-group {
      gap: 8px;

      .gap{
        border-radius: 100px;
        &:first-child{
          border: 1px solid var(--gray-100);
        }
        // &:last-child{
        //   border: 1px solid var(--gray-100);
        // }
      }

      .btn-cancel {
        color: var(--gray-100);
        background-color: #F8F7EF !important;
      }
      .primary {
        color: #fff;
        border: none;
      }
      button{
        font-size: 16px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.2px;
      }
    }
  }

  .popup-container {
    max-width: 380px;
    padding: 30px;

    .popup-title {
      padding-top: 16px;
      height: auto;
      text-align: center;
      border-bottom: 0;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.3px;
    }

    .popup-content {
      padding: 0;

      .description {
        padding-top: 8px;
        text-align: center;
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.3px;
        word-wrap: break-word;
      }

      .button-group {
        margin-top: 30px;
        width: 100%;

        .gap {
          width: 100%;
          height: 54px;

          & > button {
            border:none;
            font-size: 16px;
            border-radius: 27px;
            background: var(--gray-100);
          }
        }
      }
    }

    .btn-close {
      display: none;
    }
  }
}
</style>
