const getDefaultState = () => {
  return {
    list: [],
    count: 0,
    originFilter: {},
    departments: [],
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    totalPages: state => {
      const limit = state.originFilter?.limit || 14
      const count = state.count || 0
      return Math.ceil(count / limit)
    },
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setResponse (state, data) {
      state.list = data.departments
      state.count = data.totalCount
    },
    setOriginFilter (state, filter) {
      state.originFilter = JSON.parse(JSON.stringify(filter))
    },
    setDepartments (state, data) {
      state.departments = data.departments
    },
  },
  actions: {
    // 부서(팀)리스트 호출
    async getList ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
              `${this._vm.SPARKONE_OFFICE_API_URL}office/departments`,
              payload,
        )

        commit('setResponse', response.data)
        commit('setOriginFilter', payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 부서(팀)삭제
    async deleteDepartment ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.delete(
              `${this._vm.SPARKONE_OFFICE_API_URL}office/department/${payload.departmentId}`,
              payload.data,
        )

      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 부서(팀)상세 호출
    async getDepartment ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
              `${this._vm.SPARKONE_OFFICE_API_URL}office/department/${payload.departmentId}`,
              payload,
        )

      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 부서(팀)수정
    async putDepartment ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.put(
              `${this._vm.SPARKONE_OFFICE_API_URL}office/department/${payload.departmentId}?membershipId=${payload.membershipId}&managerType=${payload.managerType}&tenantId=${payload.tenantId}&managerId=${payload.managerId}`,
              payload.data,
        )

      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 부서(팀) 등록
    async postDepartment ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.post(
              `${this._vm.SPARKONE_OFFICE_API_URL}office/department?membershipId=${payload.membershipId}&managerType=${payload.managerType}&tenantId=${payload.tenantId}&managerId=${payload.managerId}`,
              payload.data,
        )

      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

  },
}
