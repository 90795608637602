<template>
  <span v-show="totalCount >= 0" class="infoBox">
    총 <span class="count">{{ getFormattedNumber(totalCount) }} </span>건의 데이터
  </span>
</template>

<script>
export default {
  name: 'SpCounter',
  props: {
    totalCount: Number,
  },
}
</script>

<style lang="scss" scoped>
.infoBox {
  line-height:normal;
  color:var(--gray-100);
  font-size: 14px;
  font-weight: 400;
  .count {
    // color:#000;
    font-weight: 600;
  }
}
</style>
