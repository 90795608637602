import Vue from 'vue'
import Vuex from 'vuex'
import '@/mixin/http'

/**
 * store 안에 js 자동 로드
 */
const context = require.context('./modules', true, /\.js/)
const modules = {}

function camelize (str) {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return '' // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase()
  })
}

context.keys().forEach((file) => {
  let moduleName = camelize(file.replace(/(\.\/|\.js$)/g, ''))
  if (moduleName.indexOf('/') !== -1) { moduleName = camelize(moduleName.split('/').pop()) }
  // moduleName = moduleName.split('/').map(folder => camelize(folder)).join('/')
  modules[moduleName] = context(file).default || context(file)
  modules[moduleName].namespaced = true
})

/**
 * Vuex 설정
 */
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    cancelTokens: [],
    requested: 0,
    pathToMenu: {},
    tree: {},
    isFolded: true,
    currentPage: 1,
    modals: [],
    toasts: [],
  },
  getters: {
    tree: (state) => {
      return Object.values(state.tree).sort(
        (a, b) => a.menuOrder - b.menuOrder,
      )
    },
    pathToMenu: (state) => (path) => {
      for (const exp in state.pathToMenu) {
        const r = new RegExp('^' + exp + '$')
        if (path.match(r)) {
          return state.pathToMenu[exp]
        }
      }
      return undefined
    },
  },
  mutations: {
    addCancelToken (state, token) {
      state.cancelTokens.push(token)
    },
    increaseRequestCount (state) {
      state.requested++
    },
    decreaseRequestCount (state) {
      state.requested--
    },
    clearCancelToken (state) {
      state.cancelTokens = []
    },
    setTree (state, tree) {
      state.tree = tree
    },
    setPathToMenu (state, menu) {
      state.pathToMenu = menu
    },
    setCurrentPage (state, pageToChange) {
      state.currentPage = pageToChange
    },
    toggleIsFolded (state) {
      state.isFolded = !state.isFolded
    },
    addModal (state, modals) {
      state.modals.push(modals)
    },
    addToast (state, toasts) {
      state.toasts.push(toasts)
    },
  },
  actions: {
    cancelPendingRequests (context) {
      // Cancel all request where a token exists
      context.state.cancelTokens.forEach((request, i) => {
        if (request.cancel) {
          request.cancel()
        }
      })
      // Reset the cancelTokens store
      context.commit('clearCancelToken')
    },
  },
  modules,
})
