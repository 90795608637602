import { render, staticRenderFns } from "./SpCheckbox.vue?vue&type=template&id=58e034dd&scoped=true"
import script from "./SpCheckbox.vue?vue&type=script&lang=js"
export * from "./SpCheckbox.vue?vue&type=script&lang=js"
import style0 from "./SpCheckbox.vue?vue&type=style&index=0&id=58e034dd&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58e034dd",
  null
  
)

export default component.exports