const getDefaultState = () => {
  return {
    list: [],
    count: 0,
    originFilter: {},
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    totalPages: state => {
      const limit = state.originFilter?.limit || 14
      const count = state.count || 0
      return Math.ceil(count / limit)
    },
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setResponse (state, data) {
      state.list = data.devices
      state.count = data.totalCount
    },
    setOriginFilter (state, filter) {
      state.originFilter = JSON.parse(JSON.stringify(filter))
    },
  },
  actions: {
    // #####기기 조회
    async getDeviceList ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
            `${this._vm.SPARKONE_OFFICE_API_URL}office/iot/devices`,
            payload,
        )
        commit('setResponse', response.data)
        commit('setOriginFilter', payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // #####기기 수정
    async putDevices ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.put(
            `${this._vm.SPARKONE_OFFICE_API_URL}office/iot/devices/${payload.device_id}?membershipId=${payload.membershipId}&managerType=${payload.managerType}&tenantId=${payload.tenantId}&managerId=${payload.managerId}`,
            payload.data,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

  },
}
