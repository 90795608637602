<template>
  <div class="gap">
    <div class="selectbox">
      <select
        :class="{'icon-white':icon === 'white'}"
        :tabindex="attrs.hasOwnProperty('readonly') ? -1 : 0"
        :id="uuid"
        :value="value"
        @input="input"
        v-on="listeners"
        v-bind="attrs"
        :style="{color:placeholder && value === '' ? '#8f8f8f' : ''}"
        >
        <slot></slot>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SpSelect',
  inheritAttrs: false,
  props: {
    value: { type: null, default: undefined },
    id: { type: String, default: '' },
    icon: { type: String, default: 'black' },
    placeholder: { type: Boolean, default: false },
  },
  data () {
    return {
      uuid: null,
    }
  },
  created () {
    this.uuid = this.id
    if (this.uuid == '') {
      this.uuid = this.uuidv4()
    }
    console.log()
  },
  methods: {
    input (event) {
      setTimeout(() => {
        this.$emit('input', event.target.value)
      }, 10)
    },
  },
  computed: {
    listeners () {
      const { input, ...listeners } = this.$listeners
      return listeners
    },
    attrs () {
      return this.$attrs
    },
  },
}
</script>

<style lang="scss" scoped>
.gap {
  max-width: 100%;
  width: 100%;
  position: relative;
  display: inline-block;
  height: 32px;
  min-width: 70px;
  box-sizing: border-box;
}
.selectbox {
  position: relative;
  display: inline-block;
  width:100%;
  height:100%;
  box-sizing: border-box;
}
.selectbox select {
  &.icon-white{
    background-image: url('@/assets/icon/icon_select_arrow-white.svg');
  }
  background: #F1EDE1 url('@/assets/icon/icon_select_arrow.svg') calc(100% - 10px) center no-repeat;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 7px;
  box-sizing: border-box;
  color: var(--gray-100);
  font-size: 15px;
  font-weight: 400;
  text-indent: 12px;
  line-height: normal;
  letter-spacing: -0.2px;
  font-family: "Pretendard", Pretendard;
  outline: none;
  -webkit-appearance: none;  /* 크롬 화살표 없애기 */
  -moz-appearance: none; /* 파이어폭스 화살표 없애기 */
  appearance: none;  /* 화살표 없애기 */
  &[disabled] {
    background: #8b877d url('@/assets/icon/icon_select_arrow-disable.svg') calc(100% - 10px) center no-repeat;
    opacity: 0.6;
  }
  text-overflow:ellipsis;
  white-space:nowrap;
}
// .selectbox select:focus {
//   border-color: var(--gray-100);
//   color: var(--gray-100);
// }
.selectbox select[readonly] {
  background-color: #ddd;
  pointer-events: none;
}
select option[value=""][disabled] {
  // display: none;
}
</style>
