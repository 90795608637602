const getDefaultState = () => {
  return {
    /* list: null,
          count: 0,
          originFilter: {}, */
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    /* totalPages: state => {
              const limit = state.originFilter?.limit || 20
              const count = state.count || 0
              return Math.ceil(count / limit)
          }, */
  },
  mutations: {
    /* resetState(state) {
              Object.assign(state, getDefaultState())
          },
          setResponse(state, data) {
              state.list = data.memberships
              state.count = data.totalCount
          },
          setOriginFilter(state, filter) {
              state.originFilter = JSON.parse(JSON.stringify(filter))
          }, */
  },
  actions: {
    // ID 찾기 인증 발송
    async postRecoveryFindId (context, payload) {
      let response = null

      try {
        response = await this._vm.post(
                  `${this._vm.SPARKONE_OFFICE_API_URL}office/recovery/find/id/mobile`
                  , payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    // ID 찾기 인증 검증하기
    async postRecoveryFindIdCheck (context, payload) {
      let response = null

      try {
        response = await this._vm.post(
                    `${this._vm.SPARKONE_OFFICE_API_URL}office/recovery/find/id/mobile/2fa/${payload.key}`
                    , payload.data,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    // PASSWORD 찾기 인증 발송
    async postRecoveryFindPassword (context, payload) {
      let response = null

      try {
        response = await this._vm.post(
                    `${this._vm.SPARKONE_OFFICE_API_URL}office/recovery/find/password/mobile`
                    , payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    // PASSWORD 찾기 인증 검증하기
    async postRecoveryFindPasswordCheck (context, payload) {
      let response = null

      try {
        response = await this._vm.post(
                      `${this._vm.SPARKONE_OFFICE_API_URL}office/recovery/find/password/mobile/2fa/${payload.key}`
                      , payload.data,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // PASSWORD 변경하기
    async putRecoveryPasswordRest (context, payload) {
      let response = null

      try {
        response = await this._vm.put(
                        `${this._vm.SPARKONE_OFFICE_API_URL}office/recovery/password/rest/${payload.key}`
                        , payload.data,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

  },
}
